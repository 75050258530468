import React from 'react';
import { Link } from 'react-router-dom';

const StyledLink = ({ to, children, onClick }) => {

    return (
        
        <Link to={to} style={linkStyles} onClick={onClick}>
            {children}
        </Link>

        );

};

const linkStyles = {

    textDecoration: 'none',
    color: 'black',
    padding: '10px 20px',
    backgroundColor: 'yellow',
    borderRadius: '5px',
    display: 'inline-block',
    marginTop: '10px',

};

export default StyledLink;