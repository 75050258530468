import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>&copy; {new Date().getFullYear()} Ideal Fitness. All rights reserved</p>
            <a href='https://pngtree.com/freepng/muscle-man-silhouette_8344026.html' style={{
                color: 'black',
                textDecoration: 'none'
            
            }}>Image from pngtree.com</a>
        </footer>
    );
};

export default Footer;