import { Link } from 'react-router-dom';
import { useState, useEffect} from 'react';
import axios from 'axios';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog }   from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom';
import classes from './weightLossPhase.module.css';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

const StepRecommender = () => {
    const [maintenanceCalories, setMaintenanceCalories] = useState('');
    const [caloriesConsumed, setCaloriesConsumed] = useState('');
    const [stepsWalked, setStepsWalked] = useState('');
    const [dailyCalorieRecommendation, setDailyCalorieRecommendation] = useState('');
    const [stepsPerDay, setStepsPerDay] = useState('');
    const [missedSteps, setMissedSteps] = useState('');
    const [missedCalories, setMissedCalories] = useState('');
    const [missedWeight, setMissedWeight] = useState('');
    const [date, setDate] = useState('');
    const [showAddMissedCaloriesAndSteps, setShowAddMissedCaloriesAndSteps] = useState(false);
    const [userBodyWeight, setUserBodyWeight] = useState('');
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [userTimeZone, setUserTimeZone] = useState('');
    const [plannedWeightLoss, setPlannedWeightLoss] = useState('');
    const [expectedWeightLoss, setExpectedWeightLoss] = useState('');
    const [actualWeightLoss, setActualWeightLoss] = useState('');
    const [changeMaintenanceCalories, setChangeMaintenanceCalories] = useState(false);
    const [actlCaloriesAccuracy, setActlCaloriesAccuracy] = useState(false);
    const [endCuttingPhase, setEndCuttingPhase] = useState(false);
    const [weightLossChangeSelection, setWeightLossChangeSelection] = useState('');
    const Navigate = useNavigate();

    useEffect(() => {
        const fetchUserTimeZone = () => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setUserTimeZone(timeZone);
        };
        fetchUserTimeZone();
    }, [])

    useEffect(() => {
        
        const fetchUserWeightLossInfo = async () => {
            
            try {

                const response = await axiosInstance.get('/api/query/user_weight_loss_info', {
                    withCredentials: true
                })
                setMaintenanceCalories(response.data.data.maintenance_calories);
                setDailyCalorieRecommendation(response.data.data.deficit_calories);

            
            } catch (error) {
                console.error(error);
        
            }
        }
        fetchUserWeightLossInfo();
    }, [])

    useEffect(() => {

        const fetchPlannedExpectedActual = async () => {

            try {
                const date = new Date();
                const isoDate = date.toISOString();
                const formattedDate = DateTime.fromISO(isoDate)
                const weekNumber = formattedDate.weekNumber;
                const dayNumber = formattedDate.weekday;
                const response = await axiosInstance.get(`/api/query/planned_expected_actual_weight_loss/${date}`, {
                    withCredentials: true
                })


                console.log(response.data.data)
                const roundedPlanned = (response.data.data.plannedWeightLoss).toFixed(2);
                const roundedExpected = (response.data.data.expectedWeightLoss).toFixed(2);
                
                
                const roundedActual = (response.data.data.actualWeightLoss).toFixed(2);

                

                
                setPlannedWeightLoss(roundedPlanned);
                setExpectedWeightLoss(roundedExpected);
                setActualWeightLoss(roundedActual);
                setActlCaloriesAccuracy(response.data.data.actlCalorieAccurate)


                const startWeek = response.data.data.startWeek;
                

                
                if ((weekNumber - startWeek) > 12 && dayNumber === 1) {
                    setEndCuttingPhase(true);
                }
                else if (weekNumber - startWeek ===1 && (expectedWeightLoss - actualWeightLoss > 0.5) && dayNumber === 1) {
                    setChangeMaintenanceCalories(true);

                } else if (weekNumber - startWeek === 2 && (expectedWeightLoss - actualWeightLoss > 0.5) && dayNumber === 1) {
                    setChangeMaintenanceCalories(true);
                } else if (weekNumber - startWeek ===3 && (expectedWeightLoss - actualWeightLoss > 0.5) && dayNumber === 1) {
                    setChangeMaintenanceCalories(true);
                }
                

            } catch (error) {
                console.error(error);
            
            }
        }
        fetchPlannedExpectedActual();
    }, [actualWeightLoss, expectedWeightLoss])


    const submitStepsandCalories = async () => {
        try {

            const currentDate = new Date();
            

            const response = await axiosInstance.post(`/api/query/add/stepsWalked/caloriesConsumed`, {
                stepsWalked,
                caloriesConsumed,
                userBodyWeight,
                currentDate: currentDate.toISOString()
            }, {
                withCredentials: true
            })
            if (response.status === 200) {
                setSubmitSuccessful(true);
            }

    
        
        } catch (error) {
            console.error(error);
        }
    }

    const AddMissedCaloriesAndSteps = () => {
        setShowAddMissedCaloriesAndSteps(true);
    }

    const setAddMissedToFalse = () => {
        setShowAddMissedCaloriesAndSteps(false);
    }
    
    
    useEffect(() => {
        const fetchStepRecommendation = async () => {
            
            if (userTimeZone) {
                try {
                    const encodedTimeZone = encodeURIComponent(userTimeZone);
                    const response = await axiosInstance.get(`/api/query/${encodedTimeZone}/step_recommendation`, {
                        withCredentials: true
                    })

                
                    setStepsPerDay(response.data.stepsPerDay);
                
                } catch (error) {
                    console.error(error);
                }
            }
        }
        fetchStepRecommendation();
    }, [userTimeZone])

    const submitAddMissedCaloriesAndSteps = async (fetchStepRecommendation) => {
        
        try {

            const response = await axiosInstance.post('/api/query/add_missed_calories_and_steps', {
                missedSteps,
                missedCalories,
                missedWeight,
                date

            }, {
                withCredentials: true
            })
            setAddMissedToFalse();
            
            
            if (response.status === 200) {
                setSubmitSuccessful(true);
                window.location.reload();
                

            }
        
        } catch (error) {
            console.error(error);
        }
        
    }

    const submitCalorieOrStepChange = async () => {
        
        if (weightLossChangeSelection !== 'leave') {
            try {
                const response = await axiosInstance.post('/api/query/change_calories_or_steps', {
                    weightLossChangeSelection,
                    expectedWeightLoss,
                    actualWeightLoss,
                    actlCaloriesAccuracy
                }, {
                    withCredentials: true
                })

                if (response.status === 200) {
                    setChangeMaintenanceCalories(false);
                    window.location.reload();

                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setChangeMaintenanceCalories(false);
        }


    }

    const switchToMaintenancePhase = async () => {
        try {
            const date = new Date();
            const isoDate = date.toISOString();
            const formattedDate = DateTime.fromISO(isoDate)
            
            const response = await axiosInstance.post('/api/query/maintenance_phase', {
                date: formattedDate
            }, {
                withCredentials: true
            })

            if (response.status === 200) {
                Navigate('/maintenance-phase');
            }
            
        } catch (error) {
            console.error(error);
        }
    }
    
    

    return (
        <div className={classes.weightLossAssistant}>
            <div>
                <h3>Weight Loss Assistant</h3>
            </div>
            <div>
                <Dialog
                    header="Maintenance Calories"
                    visible={changeMaintenanceCalories}
                    style={{ width: '50vw' }}
                    onHide={() => setChangeMaintenanceCalories(false)}

                >
                    {actlCaloriesAccuracy && (expectedWeightLoss - actualWeightLoss > 0.5) && (

                        <div>
                            <p>Your actual weight loss isn't as expected. If You
                                have been logging your calories and steps accurately,
                                we should increase your steps or decrease your calories. 
                            </p>
                            <Dropdown
                                value={weightLossChangeSelection}
                                options={[
                                   { label: 'Increase Steps', value: 'steps'},
                                   { label: 'Decrease Calories', value: 'calories'},
                                   { label: 'Leave as is', value: 'leave'}
                                ]}
                                onChange={(e) => setWeightLossChangeSelection(e.value)}
                                placeholder="Select an Option"
                            />
                            <Button
                                label="Submit"
                                onClick={submitCalorieOrStepChange}
                            />
                            
                        </div>
                    )
                        
                    }
                    {!actlCaloriesAccuracy && (
                        <div>
                            <p>Your actual weight loss isn't as expected. However, we do not
                                have enough body weight data to make a recommendation. If you
                                have been logging your calories and steps accurately, and your decrease in body
                                weight change is lower than expected, we should increase your steps or decrease your calories.
                            </p>
                            <Dropdown
                                value={weightLossChangeSelection}
                                options={[
                                    { label: 'Increase Steps', value: 'steps'},
                                    { label: 'Decrease Calories', value: 'calories'},
                                    { label: 'Leave as is', value: 'leave'}
                                 ]}
                                onChange={(e) => setWeightLossChangeSelection(e.value)}
                                placeholder="Select an Option"
                            />
                            <Button
                                label="Submit"
                                onClick={submitCalorieOrStepChange}
                            />
                        </div>
                    )
                    
                    }
                </Dialog>
                
            </div>
            <div>
                <Dialog
                    header="End of Cutting Phase"
                    visible={endCuttingPhase}
                    style={{ width: '50vw' }}
                    onHide={() => setEndCuttingPhase(false)}
                >
                    <div>
                        <p>
                            It has been 12 weeks since you started your cutting phase. If you still have weight to lose,
                            we highly recommend that you switch to a maintenance phase for 2-4 weeks to reduce your dieting fatigue.
                        </p>
                        <Button
                            label="Maintenance Phase"
                            onClick={switchToMaintenancePhase}
                        />

                    </div>
                   
                </Dialog>
            </div>
            <div className={classes.weightLossRecommendationsParent}>
                <div className={classes.weightLossRecommendations}>
                    <label>Maint. Calories:</label>
                        <div>{Math.floor(maintenanceCalories)}</div>
                    <div>|</div>
                    <label>Cutting Calories</label>
                        <div> {dailyCalorieRecommendation}</div>
                </div>
                    <div className={classes.weightLossRecommendations}>
                        <label>Step Rec:</label>
                            <div> {stepsPerDay} </div>
                    </div>
            </div>
            <div className={classes.plannedExpectedActualParent}>
                <div style={{fontWeight: 'bold'}}>Weight Loss</div>
                <div className={classes.plannedExpectedActual}>
                    <label style={{fontWeight: 'bold'}}>Planned:</label>
                    <div>{plannedWeightLoss} lbs</div>
                    <div>|</div>
                    <label style={{fontWeight: 'bold'}}>Expected:</label>
                    <div>{expectedWeightLoss} lbs</div>
                    <div>|</div>
                    <label style={{fontWeight: 'bold'}}>Actl:</label>
                    <div>{actualWeightLoss} lbs</div>
                </div>

            </div>

            <div className={classes.cutCalorieWeightAndStepsInput}>
                <div>
                    <label> Steps:</label>
                            <div>
                                <input
                                    value={stepsWalked}
                                    onChange={(e) => setStepsWalked(e.target.value)}
                                    placeholder="Steps"
                                    className={classes.cutInputBoxes}
                                />
                            </div>
                </div>
                <div>
                    <label>Calories:</label>
                            <div>
                                <input
                                    value={caloriesConsumed}
                                    onChange={(e) => setCaloriesConsumed(e.target.value)}
                                    placeholder="Calories"
                                    className={classes.cutInputBoxes}
                                />
                            </div>
                </div>
                <div>
                    <label>Weight:</label>
                            <div>
                                <input
                                    value={userBodyWeight}
                                    onChange={(e) => setUserBodyWeight(e.target.value)}
                                    placeholder="Weight"
                                    className={classes.cutInputBoxes}
                                />
                            </div>
                </div>
            </div>
                <Button
                    label='Submit'
                    onClick={submitStepsandCalories}
                    style={{
                        marginTop: "10px",

                    }}
                />
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div>Missed a day? Click below to add!</div>
                    <Button
                        label='Add Day'
                        style={{
                            marginTop: "10px",
                            width: '6rem'

                        }}
                        onClick={AddMissedCaloriesAndSteps}
                    
                    />
                    {showAddMissedCaloriesAndSteps && (

                        <div>
                            <label> Steps Walked:</label>
                            <div>
                                <InputNumber
                                    value={missedSteps}
                                    onValueChange={(e) => setMissedSteps(e.value)}
                                    placeholder="Number of Steps"
                                />
                            </div>
                            <div>
                                <label>Calories Consumed:</label>
                                <div>
                                    <InputNumber
                                        value={missedCalories}
                                        onValueChange={(e) => setMissedCalories(e.value)}
                                        placeholder="Calories Consumed"
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Weight:</label>
                                <div>
                                    <InputNumber
                                        value={missedWeight}
                                        onValueChange={(e) => setMissedWeight(e.value)}
                                        mode='decimal'
                                        maxFractionDigits={1}
                                        placeholder="Weight"
                                    />
                                </div>
                            </div>
                            <label>Date:</label>
                            <div>
                                <Calendar
                                    value={date}
                                    onChange={(e) => setDate(e.value)}
                                    dateFormat="yy/mm/dd"
                                />
                            </div>
                            <Button
                                label='Submit'
                                style={{
                                    marginTop: "10px",

                                }}
                                onClick={submitAddMissedCaloriesAndSteps}
                            />
                            <Button
                                label='Cancel'
                                style={{
                                    marginTop: "10px",

                                }}
                                onClick={() => setShowAddMissedCaloriesAndSteps(false)}
                            />
                        </div>
                        
                        
                        
                    )}
                    {submitSuccessful && (
                    
                    <div>Successfully added steps and calories!</div>
                    
                    )}
                    

                </div>
                <div>
                    <Link to='/weightLoss'>Want to change your weight goals? Click here</Link>
                </div>
            </div>   
    )
}

export default StepRecommender;