import React, { useEffect, useState} from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import StyledLink from '../../styledLink';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});



const RoutineSelection = () => {
    const [routineNames, setRoutineNames] = useState([]);
    const [selectedRoutine, setSelectedRoutine] = useState('');
    const [userId] = useState('');
    const [userPrograms, setUserPrograms] = useState([]);
    const [userCreatedPrograms, setUserCreatedPrograms] = useState([]);
    const [programAdded, setProgramAdded] = useState(false);

    useEffect(() => {
        const fetchRoutineNames = async () => {
            try {
                const response = await axiosInstance.get('/api/query/routines');
                
                const fetchedRoutineNames = response.data.data.map((routine) => routine.routine_name);
                setRoutineNames(fetchedRoutineNames);
                
            } catch (error) {
                console.error('Error fetching routine names: ', error);
            }
        };
        fetchRoutineNames();
    }, []);

    const handleAddProgram = async () => {
        try {

            const response = await axiosInstance.post('/api/query/add_program', {
                userId,
                selectedRoutine
            }, {
                withCredentials: true
            
            });

            if (response.status === 200) {
                setProgramAdded(true);


            }
            
        } catch (error) {
            console.error('Error adding program: ', error)
        }
    };
    useEffect(() => {
        const retrieveUserPrograms = async () => {

            try {
                const response = await axiosInstance.get('/api/query/user_programs', {
                    withCredentials: true
                });

                const fetcheduserPrograms = response.data.data.map((routine) => routine.routine_name);
                setUserPrograms(fetcheduserPrograms);

            } catch (error) {
                console.error('Error fetching user programs: ', error);
            }

        };
        retrieveUserPrograms();
    }, [programAdded]);

    useEffect(() => {
        
        const retrieveUserCreatedPrograms = async () => {
            try {
                const response = await axiosInstance.get('/api/query/user_created_programs', {
                    withCredentials: true
                });
                console.log(response.data.data);
                const fetchedUserCreatedPrograms = 
                response.data.data.map((routine) => routine.routine_name);
                setUserCreatedPrograms(fetchedUserCreatedPrograms);

            
            } catch (error) {
                console.error('Error fetching user programs: ', error);
            }
        }
        retrieveUserCreatedPrograms();
    }, []);
    return (
        <div>
            <label>Select Routine:</label>
            <Dropdown
                value={selectedRoutine}
                options={routineNames}
                onChange={(e) => setSelectedRoutine(e.value)}
                placeholder='Select a Routine'
            
            />

            
                <div>
                    {selectedRoutine && (
                        <Button label= 'Add Program' onClick={handleAddProgram}
                            style={{
                                backgroundColor: 'Yellow',
                                color: 'black',
                                padding: '6px 12px',
                                border: 'solid 0.5px black',
                                borderRadius: '4px',
                            }}
                    
                        />
                                
                                
                                )}
                </div>
            <h2>Your Ideal Fitness Programs</h2>
            <ul>
                {userPrograms.map((program) => (
                    <li key={program}>
                        <Link to={`/exercises/${program}`}>{program}</Link>
                    </li>
                ))}
            </ul>
            <h2>Your Personal Programs</h2>
            <ul>
                {userCreatedPrograms.map((program) => (
                    <li key={program}>
                        <Link to={`/user-created-exercises/${program}`}>{program}</Link>
                    </li>
                ))}
            </ul>
            <div>
                <StyledLink to="/add-program">Add Program</StyledLink>
                
            </div>


        </div>
    );

  
};

export default RoutineSelection;