import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import StyledLink from '../../styledLink';
import { handleLogout } from '../Logout/logout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import classes from './header.module.css';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});





const Header = () => {
    const [visibleRight, setVisibleRight] = useState(false);
    const navigate = useNavigate();

    const navigateWeightLoss = async () => {
        
        try {

            const response = await axiosInstance.get('/api/query/user_weight_loss_helper_present', {
                withCredentials: true
            })

            if (response.status === 200) {
                navigate('/step-recommender')
            } else if (response.status === 204) {
                navigate('/weight-gain-recommender')
                
            } else if (response.status === 205) {
                navigate('/maintenance-phase')
            } else if (response.status === 201) {
                navigate('/weightloss')
                
            }


        
        } catch (error) {
            console.error(error);
        }

    }
    return(
        <header>
            <h1 className={classes.title}>IDEAL FITNESS</h1>
            <div>
                <div style={{float: 'right'}}><Button icon="pi pi-bars"   onClick={() => setVisibleRight(true)} style={{ backgroundColor: 'black', color: 'white'}} /></div>
                <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                    <h1>Menu</h1>
                    <StyledLink to="/">Home</StyledLink>
                    <br />
                    <StyledLink to="/routine_Selection">Routine Selection</StyledLink>
                    <br />
                    <StyledLink onClick={navigateWeightLoss}>Weight Assistant</StyledLink>
                    <br /> 
                    <StyledLink to="/profile">Profile</StyledLink>
                    <br />
                    <StyledLink onClick={handleLogout} to='/'>Log Out</StyledLink>
                    
                </Sidebar>

            </div>

        

        </header>
    );
};

export default Header;