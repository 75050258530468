import { React, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import * as yup from 'yup';
import classes from './addProgram.module.css';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});



const restSchema = yup.string().matches(/^(\d|-)+(\d)?$|^(\d)+$/, 'Invalid input')
  .test(
    'is-valid-range',
    'Invalid range: Must be a single integer less than 10 or a range of integers less than 10 (e.g., "2-3")',
    value => {
      
        if (!value.includes('-')) {
        // Single integer case
        const intValue = parseInt(value);
        return intValue <= 10 && Number.isInteger(intValue);
      } else {
        // Range case
        const [start, end] = value.split('-').map(Number);
        return (
          start < 10 &&
          end <= 10 &&
          Number.isInteger(start) &&
          Number.isInteger(end) &&
          start <= end
        );
      }
    }
  );

  const rpeSchema = yup.string().matches(/^(\d+(\.\d+)?|\d+(\.\d+)?-\d+(\.\d+)?)$/, 'Invalid input')
    .test(
        'is-valid-range',
        'Invalid range: Must be a single decimal or integer number between 1 and 10',
        value => {
            if (!value.includes('-')) {
                const floatValue = parseFloat(value);
                return floatValue <= 10 && floatValue >= 1 && Number.isFinite(floatValue);
            
            } else {
                const [start, end] = value.split('-').map(parseFloat);
                return (
                    start <= 10 && start >= 1 &&
                    end <= 10 &&
                    Number.isFinite(start) &&
                    Number.isFinite(end) &&
                    start <= end
                )
            }
        }
    )

  const repSchema = yup.string().matches(/^(\d|-)+(\d)?$|^(\d)+$/, 'Invalid input')
    .test(
        'is-valid-range',
        'Invalid range: Must be a single integer less than 100 or a range of integers less than 100 (e.g., "2-3")',
        value => {
            if (!value.includes('-')) {
                const intValue = parseInt(value);
                return intValue < 100 && Number.isInteger(intValue);
            } else {
                const [start, end] = value.split('-').map(Number);
                return (
                    start < 100 &&
                    end < 100 &&
                    Number.isInteger(start) &&
                    Number.isInteger(end) &&
                    start <= end
                )
            }
        } 
  )

  const setSchema = yup.string().matches(/\b(?:[1-9]|10)\b/, 'Invalid input')

  const warmUpSchema = yup.string().matches(/\b(?:[0-9]|10)\b/, 'Invalid input')

  const PercentOneRMSchema = yup.string().matches(/^\d+(\.\d+)?(-\d+(\.\d+)?)?$/, 'Invalid input')
    .test(
        'is-valid-range',
        'Invalid range: Must be a single decimal number less than 100 or a range of decimal numbers less than 100 (e.g., "77.5-85.0")',
        value => {
            if (!value.includes('-')) {
                const floatValue = parseFloat(value);
                return floatValue < 100 && floatValue > 0 && Number.isFinite(floatValue);
            } else {
                const [start, end] = value.split('-').map(parseFloat);
                return (
                    start < 100 && start > 0 &&
                    end < 100 &&
                    Number.isFinite(start) &&
                    Number.isFinite(end) &&
                    start <= end
                )
            }
        }
    )

    const createInvalidObject = () => {
        const obj = {};
        for (let i =0; i < 12; i++) {
            obj[i] = {
                repInvalid: false,
                setInvalid: false,
                warmUpInvalid: false,
                rpeInvalid: false,
                percent1RMInvalid: false,
                restInvalid: false,
                activatePercent1RM: false
                
            }
        }
        return obj;
    }
    


const AddProgram = () => {
    const [inputValues, setInputValues] = useState(['']);
    const [programnName, setProgramName] = useState('');
    const [phase, setPhase] = useState('');
    const [week, setWeek] = useState('');
    const [day, setDay] = useState('');
    const [exercises, setExercises] = useState([]);
    const [programData, setProgramData] = useState({});
    const [invalidObjectTracker, setInvalidObjectTracker] = useState(createInvalidObject());
    const [addProgramDayInvalid, setAddProgramDayInvalid] = useState(false);
    const [programNameNotPresent, setProgramNameNotPresent] = useState(true);
    const [invalidObjectTrackerContainsTrue, setInvalidObjectTrackerContainsTrue] = useState(false);
    const [createdPrograms, setCreatedPrograms] = useState([]);

    useEffect(() => {
        const checkInvalid = () => {
            const hasInvalid = Object.values(invalidObjectTracker).some(obj => {
                return Object.entries(obj).some(([key, value]) => key !== 'activatePercent1RM' && value === true);
            });
            
            
            setInvalidObjectTrackerContainsTrue(hasInvalid);

        };
    
        checkInvalid();
    
    }, [invalidObjectTracker]);

    useEffect(() => {
        const checkProgramName = () => {
            if (programnName) {
                setProgramNameNotPresent(false);
            } else {
                setProgramNameNotPresent(true);
            }
        }
        checkProgramName();
    }, [programnName], [programNameNotPresent])

    useEffect(() => {
        if (invalidObjectTrackerContainsTrue || programNameNotPresent) {
            setAddProgramDayInvalid(true);
        } else {
            setAddProgramDayInvalid(false);
        }
    }, [invalidObjectTrackerContainsTrue, programNameNotPresent])

    
    
    
    const updateInvalidObjectTracker = (key, property, value) => {
        const newObject = {...invalidObjectTracker};
        const updateNestedObject = {...newObject[key], [property]: value};
        newObject[key] = updateNestedObject;

        setInvalidObjectTracker(newObject);
    }
    
    const handleAddExercise = (index, field, value) => {
        
        setProgramData((prevData) => ({
            ...prevData,
            [index]: {
                ...prevData[index],
                [field]: value
            }
        }))

        
        if (field === 'rest') {
            if (value === '') {
                updateInvalidObjectTracker(index, 'restInvalid', false);
            } else {
                restSchema.isValid(value).then(isValid => {
                    updateInvalidObjectTracker(index, 'restInvalid', !isValid);
                    
                })
            }
        } else if (field === 'reps') {
            if (value === '') {
                updateInvalidObjectTracker(index, 'repInvalid', false);
            } else if (value === 'AMRAP') {
                updateInvalidObjectTracker(index, 'repInvalid', false);
            } else {
                repSchema.isValid(value).then(isValid => {
                    updateInvalidObjectTracker(index, 'repInvalid', !isValid);
                    
                })
            }
        } else if (field === 'sets') {
            if (value === '') {
                updateInvalidObjectTracker(index, 'setInvalid', false);
            } else {
                setSchema.isValid(value).then(isValid => {
                    updateInvalidObjectTracker(index, 'setInvalid', !isValid);
                    
                
                })
            }
            
        } else if (field === 'warmUp') {
            if (value === '') {
                updateInvalidObjectTracker(index, 'warmUpInvalid', false);
            } else {
                warmUpSchema.isValid(value).then(isValid => {
                    updateInvalidObjectTracker(index, 'warmUpInvalid', !isValid);
                })
            }
        } else if (field === 'rpe') {
            if (value === '') {
                updateInvalidObjectTracker(index, 'rpeInvalid', false);
            } else {
                rpeSchema.isValid(value).then(isValid => {
                    updateInvalidObjectTracker(index, 'rpeInvalid', !isValid);
                
                })
            }
        } else if (field === 'percent1RM') {
            if (value === '') {
                updateInvalidObjectTracker(index, 'percent1RMInvalid', false);
            } else {
                PercentOneRMSchema.isValid(value).then(isValid => {
                    updateInvalidObjectTracker(index, 'percent1RMInvalid', !isValid);
                })
            }
        } else if (field === 'exerciseName') {
            updateInvalidObjectTracker(index, 'activatePercent1RM', verify1RMWorkout(value));
        } 
    }
    

    const addInput = useCallback(() => {
        if (inputValues.length < 12) {
            setInputValues([...inputValues, '']);
        }

    }, [inputValues])


    useEffect(() => {
        
        const queryExercises = async () => {
            try {

                const response = await axiosInstance.get('api/query/exercises');
                const fetchedExercises = response.data.data.map((exercise) => ({
                    label: exercise.exercise_name,
                    value: exercise.exercise_name
                }))
                fetchedExercises.sort((a,b) => {
                    const exerciseNameA = a.label.toUpperCase();
                    const exerciseNameB = b.label.toUpperCase();

                    if (exerciseNameA < exerciseNameB) {
                        return -1;
                    }
                    if (exerciseNameA > exerciseNameB) {
                        return 1;
                    }
                    return 0;
                });
                setExercises(fetchedExercises);


            
            } catch (error) {
                console.log(error);
            }
        }
        queryExercises();
    }, [])

    useEffect(() => {
        const fetchProgramnNames = async () => {
            try {

                const reponse = await axiosInstance.get('/api/query/userCreatedPrograms', {
                    withCredentials: true
                });
                const fetchedProgramNames = reponse.data.userCreatedPrograms.map((program) => ({
                    label: program.routine_name,
                    value: program.routine_name
                }))
                setCreatedPrograms(fetchedProgramNames);
            
            } catch (error) {
                console.log(error);
            }
        }
        fetchProgramnNames();
    }, [])

    useEffect(() => {
        setProgramData({});
        setInputValues(['']);
        setInvalidObjectTracker(createInvalidObject());
    }, [day])
    
    useEffect(() => {
        const checkForExistingExerciseDay = async () => {
            if (programnName && phase && week && day) {
                setProgramData({});
                setInputValues(['']);
                setInvalidObjectTracker(createInvalidObject());
                try {

                    const response = await axiosInstance.get(`/api/query/userCreatedExerciseDay/${programnName}/${phase}/${week}/${day}`, {
                        withCredentials: true
                    })
                    if (response.data.exercises.length > 0) {
                        const fetchedProgramExercises = response.data.exercises.map((exercise) => ({
                            exerciseOrder: exercise.exercise_day_order,
                            exerciseName: exercise.exercise_name,
                            warmUp: exercise.warm_up_sets,
                            sets: exercise.sets,
                            reps: exercise.reps,
                            rpe: exercise.rpe,
                            percent1RM: exercise.percent1rm,
                            rest: exercise.rest,
                            notes: exercise.notes,
                            


            
                        }))
                        setProgramData(fetchedProgramExercises);
                        const tempObject = createInvalidObject();
                        setTimeout(() => {
                            for (let i =0; i < fetchedProgramExercises.length; i++) {
                                tempObject[i]['activatePercent1RM'] = verify1RMWorkout(fetchedProgramExercises[i]?.exerciseName);
                            }
                            return tempObject
                        }, 0)
                        setInvalidObjectTracker(tempObject);
                    }
 
                
                } catch (error) {
                    console.log(error);
                }
            }
        }
        checkForExistingExerciseDay();
    }, [programnName, phase, week, day])    

    useEffect(() => {
        
        if (inputValues.length < programData.length) {
            addInput();
        }
        
    }, [inputValues, addInput, programData.length])


    const addProgramDay = async () => {
        try {
            const response = await axiosInstance.post('/api/add/addProgramDay', {
                programName: programnName,
                phase: phase,
                week: week,
                day: day,
                programData: programData            
            
        }, {
            withCredentials: true
        });

        console.log(response);

        if (response.status === 200) {
            console.log('Program Day Added');
        } else if (response.status === 400) {
            console.log('Program Day not added');
        }
        } catch (error) {
            console.log(error);
        }
    }


    const handleKeyPress = (e) => {
        const allowedCharacters = /^[0-9-]$/

        if (!allowedCharacters.test(e.key) && e.key !== 'Backspace') {
            e.preventDefault();
        }
    }

    const verify1RMWorkout = (exerciseName) => {
        
        let checkValid
        if (exerciseName === 'Back Squat' || exerciseName === 'Barbell Bench Press' || exerciseName === 'Deadlift' || exerciseName === 'Barbell Overhead Press' || exerciseName === 'Overhead Press' || exerciseName === 'Pause Deadlift' || exerciseName === 'Pause Barbell Bench Press') {
            checkValid = true;
        } else {
            checkValid = false;
        }
        return checkValid;
    }


    return (
        <div>
            <h1>Program</h1>
                
                    <div>
                        <label>Program Name</label>
                        <div>
                            <Dropdown
                                value={programnName}
                                onChange={(e) => setProgramName(e.value)}
                                options={createdPrograms}
                                editable
                            />
                        </div>
                    </div>
                <div className={classes.phaseDayWeekCreation}>
                            <div>
                                <span className="p-float-label w-full md:w-6rem">
                                    <Dropdown
                                        value={phase}
                                        options={[
                                            {label: '1', value: '1'},
                                            {label: '2', value: '2'},
                                            {label: '3', value: '3'},
                                            {label: '4', value: '4'},
                                            {label: '5', value: '5'},
                                            {label: '6', value: '6'}
                                        ]}
                                        onChange={(e) => setPhase(e.target.value)}
                                        className={classes.phaseDayWeekCreation}
                                    />
                                    <label>Phase</label>
                                </span>
                            </div>
                            <div>
                                <span className="p-float-label w-full md:w-6rem">
                                    <Dropdown
                                        type='text'
                                        value={week}
                                        options={[
                                            {label: '1', value: '1'},
                                            {label: '2', value: '2'},
                                            {label: '3', value: '3'},
                                            {label: '4', value: '4'},
                                            {label: '5', value: '5'},
                                            {label: '6', value: '6'},
                                            {label: '7', value: '7'},
                                            {label: '8', value: '8'},
                                            {label: '9', value: '9'},
                                            {label: '10', value: '10'},
                                            {label: '11', value: '11'},
                                            {label: '12', value: '12'}
                                        ]}
                                        onChange={(e) => setWeek(e.target.value)}
                                        className={classes.phaseDayWeekCreation}
                                    />
                                    <label>Week</label>
                                </span>
                            </div>
                            <div>
                                <span className="p-float-label w-full md:w-5rem">
                                    <Dropdown
                                        type='text'
                                        value={day}
                                        options={[
                                            {label: '1', value: '1'},
                                            {label: '2', value: '2'},
                                            {label: '3', value: '3'},
                                            {label: '4', value: '4'},
                                            {label: '5', value: '5'},
                                            {label: '6', value: '6'}
                                        ]}
                                        onChange={(e) => setDay(e.target.value)}
                                        className={classes.phaseDayWeekCreation}
                                    />
                                    <label>Day</label>
                                </span>
                            </div>
                </div>
                <h1>Exercises</h1>
                
                { phase && day && week && inputValues.map((_, index) => (
                    <div key={index}>
                        <div>Exercise {index + 1}</div>
                        <Dropdown
                            value={programData[`${index}`]?.exerciseName || ''}
                            onChange={(e) => handleAddExercise(index, 'exerciseName', e.target.value)}
                            placeholder='Exercise Name'
                            options={exercises}
                            filter
                        />
                        <div className={classes.exerciseCreation}>
                            <div>
                                <span className="p-float-label">
                                    <InputText
                                        value={programData[`${index}`]?.warmUp || ''}
                                        onChange={(e) => handleAddExercise(index, 'warmUp', e.target.value)}
                                        size='11'
                                        className={invalidObjectTracker[index]['warmUpInvalid'] ? 'p-invalid' : ''}
                                    />
                                    <label>Warm-up Sets</label>
                                </span>
                            </div>
                            <div>
                                <span className="p-float-label">
                                    <InputText
                                        value={programData[`${index}`]?.sets || ''}
                                        onChange={(e) => handleAddExercise(index, 'sets', e.target.value)}
                                        size='3'
                                        className={invalidObjectTracker[index]['setInvalid'] ? 'p-invalid' : ''}
                                    />
                                    <label>Sets</label>
                                </span>
                            </div>
                            <div>
                                <span className="p-float-label">
                                    <InputText
                                        value={programData[`${index}`]?.reps || ''}
                                        onChange={(e) => handleAddExercise(index, 'reps', e.target.value)}
                                        size='3'
                                        className={invalidObjectTracker[index]['repInvalid'] ? 'p-invalid' : ''}
                                    />
                                    <label>Reps</label>
                                </span>
                            </div>
                            <div>
                                <span className="p-float-label">
                                    <InputText
                                        value={programData[`${index}`]?.rpe || ''}
                                        onChange={(e) => handleAddExercise(index, 'rpe', e.target.value)}
                                        size='3'
                                        className={invalidObjectTracker[index]['rpeInvalid'] ? 'p-invalid' : ''}

                                    />
                                    <label>RPE</label>
                                </span>
                            </div>
                            <div>
                                <span className="p-float-label">
                                    <InputText
                                        value={programData[`${index}`]?.percent1RM || ''}
                                        onChange={(e) => handleAddExercise(index, 'percent1RM', e.target.value)}
                                        size='5'
                                        className={invalidObjectTracker[index]['percent1RMInvalid'] ? 'p-invalid' : ''}
                                        disabled={invalidObjectTracker[index]['activatePercent1RM'] ? false : true}

                                    />
                                    <label>%1RM</label>
                                </span>
                            </div>
                            <div>
                                <span className="p-float-label">
                                    <InputText
                                        value={programData[`${index}`]?.rest || ''}
                                        onChange={(e) => handleAddExercise(index, 'rest', e.target.value)}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                        size='3'
                                        className={invalidObjectTracker[index]['restInvalid'] ? 'p-invalid' : ''}
                                    />
                                    <label>Rest</label>
                                </span>
                            </div>
                        </div>
                            <div className={classes.exerciseCreationNote}>
                                <span className="p-float-label">
                                    <InputText
                                        value={programData[`${index}`]?.notes || ''}
                                        onChange={(e) => handleAddExercise(index, 'notes', e.target.value)}
                                        size='20'
                                    />
                                    <label>Notes</label>
                                </span>
                            </div>
                        
                    </div>
                ))}

                <div>
                    <Button
                        label= 'Add Exercise'
                        onClick={addInput}
                        style={{
                            marginTop: "10px",
                        }}

                    />
                </div>
                <div>
                    <Button
                        label='Add Program Day'
                        onClick={addProgramDay}
                        style={{
                            marginTop: "10px",
                        }}
                        disabled={addProgramDayInvalid}
                    />
                </div>
        </div>

                
        )
}

export default AddProgram;