import React, { useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import classes from './userInfoForm.module.css';
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,})



const UserInfoForm = () => {
    const navigate = useNavigate();
    const [weight, setWeight] = useState('');
    const [age, setAge] = useState('');
    const [yearsLifting, setYearsLifting] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [deadlift1RM, setDeadlift1RM] = useState('');
    const [squat1RM, setSquat1RM] = useState('');
    const [bench1RM, setBench1RM] = useState('');
    const [overheadPress1RM, setOverheadPress1RM] = useState('');
    const [gender, setGender] = useState('');
    const [heightFt, setHeightFt] = useState('');
    const [heightIn, setHeightIn] = useState('');


    const submitUserInfo = async(e) => {
        e.preventDefault();


        try {

            await axiosInstance.post('/api/add/user_info', {
                weight,
                heightFt,
                heightIn,
                age,
                yearsLifting,
                firstName,
                lastName,
                gender,
                deadlift1RM,
                squat1RM,
                bench1RM,
                overheadPress1RM
            
            }, {
                withCredentials: true
            });
        
        } catch (error) {
            console.error(error.response.data);
        }

        navigate('/routine_Selection');


    }
    
    
    
    return (
        <div>
            <h1>User Info</h1>
            <form onSubmit={submitUserInfo}>
                    <div>
                        <label>
                            First Name:
                            <div>
                                <InputText
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="First Name"
                                />
                            </div>
                        </label>
                    </div>
                <div>
                    <label>
                        Last Name:
                        <div>
                            <InputText
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        Gender:
                        <div>
                            <Dropdown
                                value={gender}
                                options={[
                                    {label: 'Female', value: 'female'},
                                    {label: 'Male', value: 'male'}
                                ]}
                                placeholder="Choose a gender"
                                onChange={(e) => setGender(e.value)}
                            />
                        </div>
                    </label>

                </div>
                <div>
                    <label>
                        Weight:
                        <div>
                            <InputText
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                placeholder="lbs"
                            />
                        </div>
                    </label>
                </div>
                <div> 
                    <label> Height:
                        <div className={classes.userInfoHeightDisplay}>
                            <span className="p-float-label w-full md:w-4rem">
                                <Dropdown
                                    value={heightFt}
                                    options={[
                                        {label: '3', value: '3'},
                                        {label: '4', value: '4'},
                                        {label: '5', value: '5'},
                                        {label: '6', value: '6'},
                                        {label: '7', value: '7'},
                                    ]}
                                    placeholder="ft"
                                    onChange={(e) => setHeightFt(e.value)}
                                />
                                <label>Ft</label>
                            </span>
                            <span className="p-float-label w-full md:w-4rem">
                                <Dropdown
                                    value={heightIn}
                                    options={[
                                        {label: '0', value: '0'},
                                        {label: '1', value: '1'},
                                        {label: '2', value: '2'},
                                        {label: '3', value: '3'},
                                        {label: '4', value: '4'},
                                        {label: '5', value: '5'},
                                        {label: '6', value: '6'},
                                        {label: '7', value: '7'},
                                        {label: '8', value: '8'},
                                        {label: '9', value: '9'},
                                        {label: '10', value: '10'},
                                        {label: '11', value: '11'},
                                    ]}
                                    placeholder="in"
                                    onChange={(e) => setHeightIn(e.value)}
                                    
                                />
                                <label>in</label>
                            </span>

                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        Age:
                        <div>
                            <InputText
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                                placeholder="Age"
                            />
                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        Years Lifting:
                        <div>
                            <InputText
                                value={yearsLifting}
                                onChange={(e) => setYearsLifting(e.target.value)}
                                placeholder="Years Lifting"
                            />
                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        Deadlift 1 Rep Max:
                        <div>
                            <InputText
                                value={deadlift1RM}
                                onChange={(e) => setDeadlift1RM(e.target.value)}
                                placeholder="lbs"
                            />
                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        Squat 1 Rep Max:
                        <div>
                            <InputText
                                value={squat1RM}
                                onChange={(e) => setSquat1RM(e.target.value)}
                                placeholder="lbs"
                            />
                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        Bench Press 1 Rep Max:
                        <div>
                            <InputText
                                value={bench1RM}
                                onChange={(e) => setBench1RM(e.target.value)}
                                placeholder="lbs"
                            />
                        </div>
                    </label>
                </div>
                <div>
                    <label>
                        Overhead Press 1 Rep Max:
                        <div>
                            <InputText
                                value={overheadPress1RM}
                                onChange={(e) => setOverheadPress1RM(e.target.value)}
                                placeholder="lbs"
                            />
                        </div>
                    </label>
                </div>
                <br/>
                <Button
                    label="Submit"
                    type="submit"
                    onClick={submitUserInfo}
                />

            </form>
        </div>
    );

}

export default UserInfoForm;