import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import Header from './Components/Header/header';
import Footer from './Components/Footer/footer';
import Home from './Components/Home/Home';
import CreateUser from './Components/CreateUserPage/createUser';
import RoutineSelection from './Components/RoutineSelection/routineSelection';
import Exercises from './Components/ExercisesPage/exercises';
import UserInfoForm from './Components/UserInfoForm/userInfoForm';
import Profile from './Components/Profile/profile';
import WeightLoss from './Components/WeightPhaseSelection/weightPhaseSelection';
import StepRecommender from './Components/WeightlossPhase/weightLossPhase';
import AddProgram from './Components/AddProgramPage/addProgram';
import UserCreatedExercises from './Components/UserExercisesPage/userCreatedExercises';
import WeightGainRecommender from './Components/WeightGainPhase/weightGainRecommender';
import MaintenancePhase from './Components/MaintenancePhase/maintenancePhase';





const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/create-user' element={<CreateUser/>} />
            <Route path='/routine_Selection' element={<RoutineSelection/>} />
            <Route path='/exercises/:program' element={<Exercises/>} />
            <Route path='/user-info' element={<UserInfoForm/>} />
            <Route path='/profile' element={<Profile/>} />
            <Route path='/weightloss' element={<WeightLoss/>} />
            <Route path='/step-recommender' element={<StepRecommender/>} />
            <Route path='/add-program' element={<AddProgram/>} />
            <Route path='/user-created-exercises/:program' element={<UserCreatedExercises/>} />
            <Route path='/weight-gain-recommender' element={<WeightGainRecommender/>} />
            <Route path='/maintenance-phase' element={<MaintenancePhase/>} />
          </Routes>
        </main>
        <Footer />
        <ToastContainer />
      </div>
    </Router>
  

  );
};

export default App;
