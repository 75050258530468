import {useState, useEffect} from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { DateTime } from 'luxon';
import axios from 'axios';
import classes from '../WeightGainPhase/Weightgain.module.css';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const MaintenancePhase = () => {
    const [maintenanceCalories, setMaintenanceCalories] = useState('');
    const [recCalories, setRecCalories] = useState('');
    const [caloriesConsumed, setCaloriesConsumed] = useState('');
    const [bodyWeight, setBodyWeight] = useState('');
    const [startWeight, setStartWeight] = useState('');
    const [currentWeight, setCurrentWeight] = useState('');
    const [showAddMissedCaloriesAndWeight, setShowAddMissedCaloriesAndWeight] = useState(false);
    const [missedCalories, setMissedCalories] = useState('');
    const [missedBodyWeight, setMissedBodyWeight] = useState('');
    const [missedDate, setMissedDate] = useState('');
    const [missedDayAddedSuccessfully, setMissedDayAddedSuccessfully] = useState(false);
    const [dayAddedSuccessfully, setDayAddedSuccessfully] = useState(false);
    const [changeMaintenanceCalories, setChangeMaintenanceCalories] = useState(false);
    const [date] = useState(new Date());


    useEffect(() => {
        const fetchMaintenanceCalories = async () => {

            try {

                const response = await axiosInstance.get(`/api/query/maintenance_calories_and_rec_calories/${date}`, {
                    withCredentials: true,
                })

                let fetchedMaintenanceCalories = (Math.floor(response.data.data));
                let fetchedRecCalories = (Math.floor(response.data.recCalories));
                setMaintenanceCalories(fetchedMaintenanceCalories);
                setRecCalories(fetchedRecCalories);

                

            } catch (error) {
                console.error('Error getting maintenance calories: ', error);
            }
        }
        fetchMaintenanceCalories();
    }, [date])

    useEffect(() => {
        const fetchStartAndCurrentWeight = async () => {
            
            try {
                const date = new Date();
                const isoDate = date.toISOString();
                const formattedDate = DateTime.fromISO(isoDate)
                const dayNumber = formattedDate.weekday;
                const response = await axiosInstance.get(`/api/query/retrieve_start_and_current_weight/${date}`, {
                    withCredentials: true,
                })
                console.log(response)
                setStartWeight(response.data.data);
                setCurrentWeight(response.data.avgBodyWeight.toFixed(1));

                if ((startWeight - currentWeight > 1.0 || startWeight - currentWeight < -1.0) && dayNumber === 1 ) {
                    setChangeMaintenanceCalories(true);
                }
                


            } catch (error) {
                console.error('Error getting start and current weight: ', error);
            
            }
        }
        fetchStartAndCurrentWeight();

    }, [currentWeight, startWeight])

    const submitCaloriesAndWeight = async () => {
        try {

            const response = await axiosInstance.post('/api/query/submit_maintenance_calories_and_weight', {
                caloriesConsumed,
                bodyWeight,
                date

            }, {
                withCredentials: true,
            })

            if (response.status === 200) {
                setDayAddedSuccessfully(true);
                setTimeout(() => {
                    setCaloriesConsumed('');
                    setBodyWeight('');
                    setDayAddedSuccessfully(false);

                }, 3000)
            }   

        } catch (error) {
            console.error('Error submitting calories and weight: ', error);
        }

    }
    const submitAddMissedCaloriesAndWeight = async () => {

        try {

            const response = await axiosInstance.post('/api/query/submit_missed_maintenance_calories_and_weight', {
                missedCalories,
                missedBodyWeight,
                missedDate

            }, {
                withCredentials: true,
            })

            if (response.status === 200) {
                setMissedDayAddedSuccessfully(true);
                setTimeout(() => {
                    setMissedCalories('');
                    setMissedBodyWeight('');
                    setMissedDayAddedSuccessfully(false);

                }, 3000)
            
            }

        } catch (error) {
            console.error('Error submitting missed calories and weight: ', error);
        
        }

    }

    const submitChangeMaintenanceCalories = async () => {
        try {
            const weightChange = startWeight - currentWeight;
            const response = await axiosInstance.post('/api/query/change_maintenance_phase_calories', {
                weightChange,
                maintenanceCalories
            }, {
                withCredentials: true,
            })

            if (response.status === 200) {
                setChangeMaintenanceCalories(false);
                window.location.reload();
            }

        } catch (error) {
            console.error('Error changing maintenance calories: ', error);
        }
    }

    return (
        <div className={classes.weightGain}>
            <h1>Maintenance Phase</h1>
            <div className={classes.mainAndBulkCalories}>
            <p style={{fontWeight: 'bold'}}>Maint. Calories: {maintenanceCalories}</p>
            <p>|</p>
            <p style={{fontWeight: 'bold'}}>Rec. Calories: {recCalories}</p>
            </div>
            <div className={classes.mainAndBulkCalories}>
                <p style={{fontWeight: 'bold'}}>Maint. Weight: {startWeight} </p>
                <p>|</p>
                <p style={{fontWeight: 'bold'}}>Current Weight: {currentWeight}</p>
            </div>

            <div className={classes.bulkCalorieAndWeightInput}>
                <div className={classes.bulkCalorieAndWeightChildren}>
                    <div>
                        <label style={{fontWeight: 'bold'}}>Daily Calorie Intake</label>
                        <InputNumber
                            value={caloriesConsumed}
                            onValueChange={(e) => setCaloriesConsumed(e.value)}
                            placeholder="Enter Calories"
                            className="p-inputtext-sm"
                        />
                    </div>
                    <div>
                        <label style={{fontWeight: 'bold'}}>Body Weight</label>
                            <InputNumber
                            value={bodyWeight}
                            onValueChange={(e) => setBodyWeight(e.value)}
                            mode="decimal"
                            minFractionDigits={1}
                            placeholder="Enter Weight(lbs)"
                            className="p-inputtext-sm"
                        />
                    </div>
                </div>
            </div>
            <div>
                <Button
                    label="Submit"
                    onClick={() => submitCaloriesAndWeight()}
                />
            </div>
            {dayAddedSuccessfully && <p style={{fontWeight: 'bold'}}>Day added successfully</p>}
            <div>
                <label>Missed a day?</label>
                <div>
                    <Button
                        label='Add Day'
                        onClick={() => {setShowAddMissedCaloriesAndWeight(!showAddMissedCaloriesAndWeight)}}
                        />
                </div>
            </div>
            {showAddMissedCaloriesAndWeight && 
                    <div>
                        <div>
                            <label>Daily Calorie Intake:</label>
                            <InputNumber
                                value={missedCalories}
                                onValueChange={(e) => setMissedCalories(e.value)}
                                placeholder="Enter Calories"
                                className="p-inputtext-sm"
                            />
                            <InputNumber
                                value={missedBodyWeight}
                                onChange={(e) => setMissedBodyWeight(e.value)}
                                mode="decimal"
                                minFractionDigits={1}
                                placeholder="Enter Weight(lbs)"
                                className="p-inputtext-sm"
                            />
                            <Calendar
                                value={missedDate}
                                onChange={(e) => setMissedDate(e.value)}
                                dateFormat="yy-mm-dd"
                                placeholder="Date"
                            />
                        </div>
                        <div>
                            <Button
                                label="Submit"
                                onClick={() => submitAddMissedCaloriesAndWeight()}
                            />

                        </div>
                        {missedDayAddedSuccessfully && <p>Missed day added successfully</p>}

                    </div>
                    
                }
                {missedDayAddedSuccessfully && <p>Missed day added successfully</p>}
                <div>
                    <Dialog
                        header="Change Maintenance Calories"
                        visible={changeMaintenanceCalories}
                        style={{width: '50vw'}}
                        onHide={() => setChangeMaintenanceCalories(false)}
                    >
                        <div>
                            <p>Your body weight has changed by more than a pound. If you think
                                this is accurate, would you like to change your maintenance
                                calories?
                            </p>
                            <Button
                                label="Yes"
                                onClick={submitChangeMaintenanceCalories}
                            />
                            <Button
                                label="No"
                                onClick={() => setChangeMaintenanceCalories(false)}
                            />
                        </div>


                    </Dialog>
                </div>
        </div>

    )
}

export default MaintenancePhase;