import React from 'react';
import Login from '../Login/logIn';
import classes from './home.module.css';



const Home = () => {
    return (
        <div className={classes.home}>
            <h1 className={classes.welcome}>Welcome to Ideal Fitness</h1>
            <Login />


        </div>
    )
}

export default Home;