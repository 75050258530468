import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const CreateUser = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordLengthMet, setPasswordLengthMet] = useState(false);
    const [passwordSpecialCharMet, setPasswordSpecialCharMet] = useState(false);
    const [passwordUpperCaseMet, setPasswordUpperCaseMet] = useState(false);
    const [isFocused , setIsFocused] = useState(false);
    const navigate = useNavigate();


    const handleCreateUser = async () => {
        try {

            const response = await axiosInstance.post('/api/auth/register', {
                email,
                password,

            });
            console.log(response.data);
            navigate('/');
        } catch (error) {
            
            if(error.response) {
                console.error('Response data: ', error.response.data);
                console.error('Response status: ', error.response.status);
                console.error('Response headers: ', error.response.headers);
            } else if (error.request) {
                console.error('No Response recieved:', error.request);
            } else {
                console.error('Error setting up the request: ', error.message);
            }
        }
    }
    
    
    const handleFocus = () => {
        setIsFocused(true);
    }
    
    const validateEmail = (email) => {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setEmailValid(isValid);
        


    }

    


    useEffect(() => {
        setFormValid(emailValid && passwordValid);
    }, [emailValid, passwordValid])
    
    const validatePassword = (password) => {

        const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
        const upperCase = /[A-Z]+/
        
        setPasswordLengthMet(password.length >= 8);
        setPasswordSpecialCharMet(specialCharacters.test(password));
        setPasswordUpperCaseMet(upperCase.test(password));

        setPasswordValid(password.length >=8 && specialCharacters.test(password) && upperCase.test(password));





    }
        
 


    
    return <div>
        <h2>Create User</h2>
        <InputText
            type='text'
            placeholder='Email'
            value={email}
            onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
    
            
            }}
            className={emailValid? '' : 'p-invalid'}
        />
        <InputText

            type='password'
            placeholder='Password'
            value={password}
            onFocus={handleFocus}
            onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target.value);
        
            
            }}
            className={passwordValid ? '' : 'p-invalid'}
        />


        <Button
            label='Create User'
            onClick={handleCreateUser}
            style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '4px',
                border: 'solid 1.5px black',
                marginTop: '5px',

            }}
            disabled={!formValid}
        />

            {isFocused && (
                <>
            <div>
                <p style={{color: passwordLengthMet ? 'green' : 'red'}}>
                    Password - Must be atleast 8 characters long
                </p>
            </div>
            <div>
                <p style={{color: passwordSpecialCharMet ? 'green' : 'red'}}>
                    Password - Must contain a special character
                </p>
            </div>
            <div>
                <p style={{ color: passwordUpperCaseMet ? 'green' : 'red'}}>
                    Password - Must contain an uppercase letter
                </p>
            </div>
            </>
            )}
    </div>
    
};

export default CreateUser;

