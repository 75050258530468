import React, { useState, useEffect } from 'react';
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})


const Profile = () => {
    const [profileDetails, setProfileDetails] = useState('');
    const [oneRepMaxes, setOneRepMaxes] = useState('');

    

    
    useEffect(() => {

        const fetchProfile = async () => {

            try {

                const response = await axiosInstance.get('/api/query/profile', {
                    withCredentials: true
                });
                const { age, email, first_name, height, last_name, oneRepMaxes, weight, years_lifting} = response.data.data;
                const mappedOneRepMaxes = oneRepMaxes.map((exercise) => ({
                    exercise: exercise.exercise,
                    weight: exercise.weight
                }));
                setProfileDetails({ age, email, first_name, height, last_name, weight, years_lifting });
                setOneRepMaxes(mappedOneRepMaxes);
                

                
            
            } catch (error) {
                console.error(error.response.data);
            }
        
        }
        fetchProfile();
    
    }, []);

    const deadlift = oneRepMaxes && oneRepMaxes.length > 0 ? oneRepMaxes[0] : null;
    const squat = oneRepMaxes && oneRepMaxes.length > 1 ? oneRepMaxes[1] : null;
    const bench = oneRepMaxes && oneRepMaxes.length > 2 ? oneRepMaxes[2] : null;
    const overheadPress = oneRepMaxes && oneRepMaxes.length > 3 ? oneRepMaxes[3] : null;

    return (
        <div>
            <h1>Profile</h1>
            <p>Name: {profileDetails.first_name} {profileDetails.last_name}</p>
            <p>Email: {profileDetails.email}</p>
            <p>Age: {profileDetails.age}</p>
            <p>Height: {profileDetails.height}</p>
            <p>Weight: {profileDetails.weight}</p>
            <p>Years Lifting: {profileDetails.years_lifting}</p>
            <h2>One Rep Maxes</h2>
            {deadlift && (
            
                <div>{deadlift.exercise}: {deadlift.weight}</div>  
            )}
            {squat && (
                <div>{squat.exercise}: {squat.weight}</div>
            )}
            {bench && (
                <div>{bench.exercise}: {bench.weight}</div>
            )}
            {overheadPress && (
                <div>{overheadPress.exercise}: {overheadPress.weight}</div>
            )}

        </div>
    )

}

export default Profile;