import React, { useEffect, useState} from "react";
import axios from 'axios';
import {DateTime} from 'luxon';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';


import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import classes from './exercises.module.css';








const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const Exercises = () => {
    const { program } = useParams();
    const [exercises, setExercises] = useState([]);
    const [phase, setPhase] = useState([]);
    const [week, setWeek] = useState([]);
    const [day, setDay] = useState([]);
    const [selectedPhase, setSelectedPhase] = useState('');
    const [selectedWeek, setSelectedWeek] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [exerciseData, setExerciseData] = useState({});
    const [dialogVisibility, setDialogVisibility] = useState({});
    const [advancedWeightDialogVisibility, setAdvancedWeightDialogVisibility] = useState({});
    const [advancedWeight, setAdvancedWeight] = useState([]);
    const [exerciseSubmitted, setExerciseSubmitted] = useState(false);
    const [exercisesPopulated, setExercisesPopulated] = useState(false);
    const [exerciseSubmissionFailed, setExerciseSubmissionFailed] = useState(false);
    const [wrongDay, setWrongDay] = useState(false);
    const [advWeightEntryDialogVisibility, setAdvWeightEntryDialogVisibility] = useState({});
    const [advWeightEntry, setAdvWeightEntry] = useState({});
    const [checkSubmission, setCheckSubmission] = useState(false);
    const [duplicateExerciseArray, setDuplicateExerciseArray] = useState([]);


    useEffect(() => {
        const fetchNextEerciseDay = async () => {
            const date = new Date();
            const isoDate = DateTime.fromJSDate(date).toISODate();

            try {

                const response = await axiosInstance.get(`/api/query/nextExerciseDay/${isoDate}/${program}`, {
                    withCredentials: true
                })

                if (response.status === 204) {
                    setWrongDay(true);
                    toast.error('Please choose a day', {theme: "dark", autoClose: 5000, hideProgressBar: true})
                } else {
                
                    const fetchedExercises = response.data.data.map((exercise, index) => ({
                        id: index,
                        exercise_name: exercise.exercise_name,
                        warm_up_sets: exercise.warm_up_sets,
                        sets: exercise.sets,
                        reps: exercise.reps,
                        rpe: exercise.rpe,
                        percent1RM: exercise.percent1RM,
                        min_rest: exercise.min_rest,
                        max_rest: exercise.max_rest,
                        notes: exercise.notes,
                        weight: exercise.weight
                    }))
                    setExercises(fetchedExercises);
                    setDuplicateExerciseArray(fetchedExercises);
                    setExercisesPopulated(true);
                    setSelectedPhase(response.data.phase_id);
                    setSelectedWeek(response.data.week_id);
                    setSelectedDay(response.data.day_id);
            }



            } catch (error) {
                console.error('Error fetching next exercise day: ', error);
                
            }
        }
        fetchNextEerciseDay();
    }, [program])
    
    useEffect(() => {
        const fetchPhase = async () => {
            
            try {
                const response = await axiosInstance.get(`/api/query/exercises/${program}/phase`, {
                    withCredentials: true});
                const fetchedPhase = response.data.phase.map((phase) => phase.phase_id);
                setPhase(fetchedPhase);
            } catch (error) {
                console.error('Error fetching phase', error);

            }
        }
        fetchPhase();
    }, [program]);

    useEffect(() => {
        const fetchWeek = async () => {
            
            if (selectedPhase) {
                try {
                    const response = await axiosInstance.get(`/api/query/exercises/${program}/${selectedPhase}/week`);
                    const fetchedWeek = response.data.week.map(week => ({ label: week.week_id, value: week.week_id}));
                    fetchedWeek.sort((a, b) => a.value - b.value)
                    setWeek(fetchedWeek);
                } catch (error) {
                    console.error('Error fetching week', error);
                }
            }    
        }
        fetchWeek();
    }, [selectedPhase, program]);

    useEffect(() => {
        const fetchDay = async () => {

            if (selectedWeek) {

                try {
                    const response = await axiosInstance.get(`/api/query/exercises/${program}/${selectedPhase}/${selectedWeek}/day`);
                    const fetchedDay = response.data.day.map(day => day.day);
                    fetchedDay.sort((a, b) => a - b)
                    setDay(fetchedDay);
                } catch (error) {
                    console.error('Error fetching day', error);
                }
            }    
        }
        fetchDay(); 
    }, [selectedWeek, program, selectedPhase])

    useEffect(() => {
        const fetchDayExercises = async () => {

            if (selectedDay) {

                try {
                    const response = await axiosInstance.get(`/api/query/exercises/${program}/${selectedPhase}/${selectedWeek}/${selectedDay}`, {
                        withCredentials: true});
                    const fetchedExercises = response.data.data.map((exercise, index) => ({
                        id: index,
                        exercise_name: exercise.exercise_name,
                        warm_up_sets: exercise.warm_up_sets,
                        sets: exercise.sets,
                        reps: exercise.reps,
                        rpe: exercise.rpe,
                        percent1RM: exercise.percent1RM,
                        min_rest: exercise.min_rest,
                        max_rest: exercise.max_rest,
                        notes: exercise.notes,
                        weight: exercise.weight
                        
                
                    }))
                    setExercises(fetchedExercises);
                    setDuplicateExerciseArray(fetchedExercises);
                    setExercisesPopulated(true);
                    


                } catch (error) {
                    console.error('Error fetching exercises: ', error);
            
                }
            }
        }
        fetchDayExercises();
    }, [selectedDay, program, selectedPhase, selectedWeek])

    useEffect(() => {
        if (exerciseSubmitted) {
            setExerciseData({});
            const timer = setTimeout(() => {
                setExerciseSubmitted(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [exerciseSubmitted])

    const handleAddUserExerciseDataOnBlur = async (exerciseID) => {
            
        if (exerciseData[exerciseID] && exerciseData[exerciseID].actualReps
            && exerciseData[exerciseID].actualRpe && exerciseData[exerciseID].weight) 
            {
                try {

                    const response = await axiosInstance.post(`/api/add/addUserExerciseData/${program}`, {
                        phase: selectedPhase,
                        week: selectedWeek,
                        day: selectedDay,
                        exerciseId: exerciseID,
                        ...exerciseData[exerciseID],
                    }, {
                        withCredentials: true
                    });
                        
                    if (response.status === 200) {
                        removeExercise(exerciseID);
                        toast.success(`${exerciseData[exerciseID].exercise_name} has been completed!`, 
                            {autoClose: 2000, progress: undefined, hideProgressBar: true, theme: "dark"})
                    }
                } catch (error) {
                    console.error('Error adding user exercise data: ', error);
                }
            }
        }
    useEffect(() => {
        if (exercises.length === 0 && exercisesPopulated) {
            toast.success('All exercises have been completed for the day!', {
                autoClose: 2000, hideProgressBar: true, theme: "dark"
            }) 
        }
    }, [exercises, exercisesPopulated])


    
    const removeExercise = (exerciseID) => {
        exerciseID = parseInt(exerciseID);
        const index = exercises.findIndex((exercise) => exercise.id === exerciseID);
        if (index !== -1) {
            setTimeout(() => {
                setExercises((prevExercises) => {
                    const newExercises = [...prevExercises];
                    newExercises.splice(index, 1);
                    return newExercises;
                }, 3000);
            })
        }

    };

    const checkSubmissionData = async () => {
        setExercises(duplicateExerciseArray);
        setCheckSubmission(true);
    }

    const handleAddUserExerciseData = async () => {
        console.log('I have been clicked')

        try {
           console.log('Adding user exercise data')
           console.log('Exercise data: ', exerciseData)
           const promises = Object.entries(exerciseData).map(([exerciseId, data]) => {
                console.log('Adding user exercise data for: ', exerciseId);
                return axiosInstance.post(`/api/add/addUserExerciseData/${program}`, {
                    exerciseId,
                    ...data,

                }, {
                    withCredentials: true
                });
            })

            const responses = await Promise.all(promises);

            const allSuccessful = responses.every((response) => response.status === 200);

            if (allSuccessful) {
                setExerciseSubmitted(true);
            } else {
                setExerciseSubmissionFailed(true);
            }

            
        } catch (error) {
            console.error('Error adding user exercise data: ', error);}
            
    }

    useEffect(() => {

    }, [])

    const handleAddUserAdvData = async () => {

        try {
           console.log('Adding user exercise data')
           console.log('Exercise data: ', advWeightEntry)
           const promises = Object.entries(advWeightEntry).map(([index, data]) => {
                console.log('Adding user exercise data for: ', index);
                return axiosInstance.post(`/api/add/advAddUserExerciseData/${program}`, {
                    index,
                    ...data,

                }, {
                    withCredentials: true
                });
            })

            const responses = await Promise.all(promises);

            const allSuccessful = responses.every((response) => response.status === 200);

            if (allSuccessful) {
                setExerciseSubmitted(true);
                setAdvWeightEntry({});
                console.log(exerciseSubmitted)
            }
            
        } catch (error) {
            console.error('Error adding user exercise data: ', error);}
        
    }





    const toggleDialog = (exerciseName) => {
        setDialogVisibility((prevVisibility) => ({
            ...prevVisibility,
            [exerciseName]: !prevVisibility[exerciseName]
        }))
    }

    const toggleAdvancedWeightDialog = (exerciseName) => {
        setAdvancedWeightDialogVisibility((prevVisibility) => ({
            ...prevVisibility,
            [exerciseName]: !prevVisibility[exerciseName]
        }))
    }

    const toggleAdvWeightEntryDialog = (exerciseName) => {
        setAdvWeightEntryDialogVisibility(prevVisibility => ({
            ...prevVisibility,
            [exerciseName]: !prevVisibility[exerciseName]
        }))
    }
    

    const handleInputChange = (exerciseId, field, value) => {
        exerciseId = parseInt(exerciseId);
        let exercise = exercises.find(exercise => exercise.id === exerciseId);
        let exerciseName = exercise.exercise_name;
        
        
        setExerciseData((prevData) => ({
            ...prevData,
            [exerciseId]: {
                ...prevData[exerciseId],
                exercise_name: exerciseName,
                [field]: value,
            },
        }));
    }

    const handleAdvWeightEntry = (exerciseId, field, index, value) => {
        exerciseId = parseInt(exerciseId);
        let exercise = exercises.find(exercise => exercise.id === exerciseId);
        let exerciseName = exercise.exercise_name;

        setAdvWeightEntry((prevData) => ({
            ...prevData,
            [index]: {
                ...prevData[index],
                exercise_name: exerciseName,
                [field]: value,
            }
        }))

    }

    
    const advancedWeightRetrieval = async (exerciseName) => {
        
        toggleAdvancedWeightDialog(exerciseName);
        try {

            const response = await axiosInstance.get(`/api/query/advanced_weight/${exerciseName}/${program}`, {
                withCredentials: true
            })

            

            setAdvancedWeight(response.data.data.map((exercise) => ({
                weight: exercise.weight,
                reps_performed: exercise.reps_performed,
                rpe_actual: exercise.rpe_actual
            })));
            
            
            
        
        } catch (error) {
            console.error('Error retrieving advanced weight: ', error);
        }
    }
    



    return (
        <div>
            
                <div className={classes.exerciseTest}>

                    <h1>Exercises</h1>
                </div>
                    <div className={classes.exerciseTest}>
                        {exercises.map((exercise) => (
                            <div className={classes.exercisesList} key={`${exercise.id}`}>
                                <div className={classes.exerciseName}>{exercise.exercise_name} </div> <div>Warm-up Sets: {exercise.warm_up_sets}, Sets: {exercise.sets}, Reps: {exercise.reps}, {exercise.rpe !== null && <> RPE: {exercise.rpe},</>} {exercise.percent1RM !== null && <> Percent: {exercise.percent1RM}%, </>} Rest: {exercise.min_rest}-{exercise.max_rest} minutes</div>
                                <Button label="Notes" onClick={() => toggleDialog(exercise.exercise_name)}
                                    style={{
                                        backgroundColor: "black",
                                        color: "white",
                                        padding: "4px 8px",
                                        border: "solid 0.5px black",
                                        borderRadius: "4px",
                                        fontSize: "12px",
                                    }} />
                                <Dialog
                                    header="Notes"
                                    visible={dialogVisibility[exercise.exercise_name] || false}
                                    style={{ width: '75vw'}}
                                    onHide={() => toggleDialog(exercise.exercise_name)}
                                >
                                    
                                    {exercise.notes &&
                                        exercise.notes.includes('http') ? (
                                            <a href={exercise.notes} target="_blank" rel="noreferrer">{exercise.notes}</a>
                                        ) : (
                                            <p>{exercise.notes}</p>    
                                        )
                                    }
                                </Dialog>
                                <div className={classes.exerciseInput}>
                                    <div>
                                            <input
                                            placeholder='Reps'
                                            type="text"
                                            value={exerciseData[`${exercise.id}`]?.actualReps || ''}
                                            onChange={(e) => handleInputChange(`${exercise.id}`, 'actualReps', e.target.value )}
                                            onBlur={() => handleAddUserExerciseDataOnBlur(`${exercise.id}`)}
                                            className={classes.exerciseInputtext}
                                            />
                                            <Button
                                                label='Adv. Entry'
                                                style={{
                                                    backgroundColor: "black",
                                                    color: "white",
                                                    padding: "3px 6px",
                                                    border: "none",
                                                    borderRadius: "4px",
                                                    fontSize: "12px",
                                                    marginTop: '5px',
                                                }}
                                                onClick={() => toggleAdvWeightEntryDialog(exercise.exercise_name)}
                                            />
                                            <Dialog
                                                header='Advanced Entry'
                                                visible={advWeightEntryDialogVisibility[exercise.exercise_name] || false}
                                                style={{ width: '100vw'}}
                                                onHide={() => toggleAdvWeightEntryDialog(exercise.exercise_name)}
                                            
                                            >
                                                <div>
                                                    {Array.from({ length: exercise.sets}, (_, i) => (
                                                        <div key={i}>
                                                            
                                                                <h3>Set {i+1}</h3>
                                                                <input
                                                                    placeholder='RPE'
                                                                    className={classes.advEntryInput}
                                                                    value={advWeightEntry[`${i}`]?.rpe || ''}
                                                                    onChange={(e) => handleAdvWeightEntry(`${exercise.id}`, 'rpe', i, e.target.value)}
                                                                    

                                                                />
                                                            
                                                            
                                                                <input
                                                                    placeholder='Reps'
                                                                    className={classes.advEntryInput}
                                                                    value={advWeightEntry[`${i}`]?.reps || ''}
                                                                    onChange={(e) => handleAdvWeightEntry(`${exercise.id}`, 'reps', i, e.target.value)}
                                                                
                                                                />
                                                            
                                                            
                                                                <input
                                                                    placeholder='Weight'
                                                                    className={classes.advEntryInput}
                                                                    value={advWeightEntry[`${i}`]?.weight || ''}
                                                                    onChange={(e) => handleAdvWeightEntry(`${exercise.id}`, 'weight', i, e.target.value)}
                                                                />
                                                            
                                                        </div>
                                                        ))}
                                                        <Button
                                                            label='Submit'
                                                            style={{
                                                                backgroundColor: "yellow",
                                                                color: "black",
                                                                padding: "6px 12px",
                                                                border: "solid 0.5px yellow",
                                                                borderRadius: "4px",
                                                                marginTop: "10px",
                                                            }}
                                                            onClick={handleAddUserAdvData}
                                                        />
                                                </div>
                                            </Dialog>
                                    </div>
                                    <div>
                                            <input placeholder='RPE'
                                            type="text"
                                            value={exerciseData[`${exercise.id}`]?.actualRpe || ''}
                                            onChange={(e) => handleInputChange(`${exercise.id}`, 'actualRpe', e.target.value)}
                                            onBlur={() => handleAddUserExerciseDataOnBlur(`${exercise.id}`)}
                                            className={classes.exerciseInputtext}
                                            />
                                    </div>
                                    <div>
                                            <input
                                            placeholder= {exercise?.weight || 'Weight'}
                                            type="text"
                                            value={exerciseData[`${exercise.id}`]?.weight || ''}
                                            onChange={(e) => handleInputChange(`${exercise.id}`, 'weight', e.target.value)}
                                            onBlur={() => handleAddUserExerciseDataOnBlur(`${exercise.id}`)}
                                            className={classes.exerciseInputtext}
                                            />
                                        <Button label='Prev. Weights' onClick={() => advancedWeightRetrieval(exercise.exercise_name)}
                                            style={{
                                                backgroundColor: "black",
                                                color: "white",
                                                padding: "3px 6px",
                                                border: "none",
                                                borderRadius: "4px",
                                                fontSize: "12px",
                                                marginTop: '5px',
                                            }}
                                    />
                                    <Dialog
                                        header='Advanced Weight Retrieval'
                                        visible={advancedWeightDialogVisibility[exercise.exercise_name] || false}
                                        style={{ width: '75vw'}}
                                        onHide={() => toggleAdvancedWeightDialog(exercise.exercise_name)}
                                    >
                                        {advancedWeight.map((exercise, index) => (
                                            <div key={index}>
                                              Weight: {exercise.weight} RPE: {exercise.rpe_actual} Reps: {exercise.reps_performed}
                        
                        
                                            </div>
                                            ))}
                                    </Dialog>
                                    </div>
                        
                                </div>
                        
                            </div>
                        ))}
                    </div>
                    <div className={classes.currPhaseDayWeek}>
                        <div className={classes.currPhaseDayWeekChildren}>
                            <label>Phase:</label>
                            <div>{selectedPhase}</div>
                        </div>
                        <div className={classes.currPhaseDayWeekChildren}>
                            <label>Week:</label>
                            <div>{selectedWeek}</div>
                        </div>
                        <div className={classes.currPhaseDayWeekChildren}>
                            <label>Day:</label>
                            <div>{selectedDay}</div>
                        </div>
                    </div>
                    <div className={classes.viewSubmission}>
                            <Button 
                                className={classes.linkButton} 
                                label='Want to verify your submission? Click here.'
                                onClick={checkSubmissionData}
                            />
                    </div>
                    <div className={classes.viewSubmission}>
                        <Button
                            className={classes.linkButton}
                            label="Want to do a different day? Click here."
                            onClick={() => setWrongDay(true)}
                        />
                    </div>

            {checkSubmission && (
            <div className={classes.exerciseSubmit}>
                <Button label="Submit" onClick={handleAddUserExerciseData}
                    style={{
                        backgroundColor: "yellow",
                        color: "black",
                        padding: "6px 12px",
                        border: "solid 0.5px yellow",
                        borderRadius: "4px",
                        marginTop: "10px",
                    }}
                />
            </div>
            )}
            {exerciseSubmitted &&
                <div>
                    {toast.success('Exercises have been submitted successfully!', 
                        {theme: "dark", autoClose: 2000, hideProgressBar: true })}
                </div>
            }
            {exerciseSubmissionFailed &&
                <div>
                    {toast.error('Error submitting exercises. Please try again.',
                    {theme: "dark", autoClose: 5000, hideProgressBar: true})}
                </div>
            }
                
            {wrongDay && (
            <div className={classes.phaseDayWeek}>
                <div>
                    <div>
                        <label>Phase:</label>
                        <div>
                            <Dropdown
                            
                                value={selectedPhase}
                                options={phase}
                                onChange={(e) => setSelectedPhase(e.value)}
                                placeholder="Select a Phase"
                                className={classes.phaseDayWeekDropdown}
                            
                            />
                        </div>
                    </div>
                </div>
                <div>
                            
                     <div>
                        <label>Week:</label>
                        <div>
                            <Dropdown
                                value={selectedWeek}
                                options={week}
                                onChange={(e) => setSelectedWeek(e.value)}
                                placeholder="Select a Week"
                                className={classes.phaseDayWeekDropdown}
                            />
                        </div>

                    </div>
                </div>
                <div>
                    <div>
                        <label>Day:</label>
                        <div>
                            <Dropdown
                                value={selectedDay}
                                options={day}
                                onChange={(e) => setSelectedDay(e.value)}
                                placeholder="Select a Day"
                                className={classes.phaseDayWeekDropdown}
                            />
                        </div>

                    </div>
                </div>
            </div>
        )}


        </div>
    )

}

export default Exercises;