import { React } from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});



const WeightLoss = () => {

    const [weightLossOrGainOptions, setWeightLossOrGainOptions] = useState([]);
    const [weeklyWeightLoss, setWeeklyWeightLoss] = useState('');
    const [returnActivityLevel, setReturnActivityLevel] = useState('');
    const [bodyWeightGoals, setBodyWeightGoals] = useState('');
    const [showWeightLossOptions, setShowWeightLossOptions] = useState(false);
    const [showWeightGainOptions, setShowWeightGainOptions] = useState(false);
    const [monthlyWeightGain, setMonthlyWeightGain] = useState('');
    const [weightGainTips, setWeightGainTips] = useState(false);
    const [weightLossTips, setWeightLossTips] = useState(false);
    const [sixteenWeeks, setSixteenWeeks] = useState('');
    const [thirtyTwoWeeks, setThirtyTwoWeeks] = useState('');
    const [dietAfterBulkWeeks, setDietAfterBulkWeeks] = useState();
    const [dietAfterBulkNeither, setDietAfterBulkNeither] = useState(false);
    const [dietAfterBulkPossible, setDietAfterBulkPossible] = useState(false);
    const navigate = useNavigate();
 

    useEffect (() => {
        
        const fetchWeightLossOrGainOptions = async () => {
        
            try {
                
                const response = await axiosInstance.get(`/api/query/weightloss_or_gain_per_week/${bodyWeightGoals}`, {
                    withCredentials: true
                
                });
                
                let fetchedWeightLossOrGainOptions;
                if (bodyWeightGoals === 'weight_loss') {
                    fetchedWeightLossOrGainOptions = response.data.data.map((option) => option.value);
                } else if (bodyWeightGoals === 'weight_gain') {
                    fetchedWeightLossOrGainOptions = response.data.data.map((option) => option.value/4);
                }
        
                setWeightLossOrGainOptions(fetchedWeightLossOrGainOptions);
    
            
            } catch (error) {
                console.error(error);
            }
        
        }
        if (bodyWeightGoals) {
            fetchWeightLossOrGainOptions();
        }
        
    }, [bodyWeightGoals])

    //Activate the weight loss, weight gain, or maintain weight options based on the user's selection
    useEffect(() => {

        if (bodyWeightGoals === 'weight_loss') {
            setShowWeightLossOptions(true);
            setShowWeightGainOptions(false);
        } else if (bodyWeightGoals === 'weight_gain') {
            setShowWeightGainOptions(true);
            setShowWeightLossOptions(false);
        } else if (bodyWeightGoals === 'maintain_weight') {
            setShowWeightLossOptions(false);
            setShowWeightGainOptions(false);
        }
        
    }, [bodyWeightGoals])

    //Calculate the date sixteen and thirty two weeks from the current date
    useEffect(() => {
        const retrieveSixteenAndThirtyTwoWeeks = async () => {
            
            const date = new Date();
            const sixteenWeeks = new Date(date.getTime() + (16 * 7 * 24 * 60 * 60 * 1000));
            const thirtyTwoWeeks = new Date(date.getTime() + (32 * 7 * 24 * 60 * 60 * 1000));
            const sixteenWeeksFormatted = sixteenWeeks.toDateString();
            const thirtyTwoWeeksFormatted = thirtyTwoWeeks.toDateString();
            setSixteenWeeks(sixteenWeeksFormatted);
            setThirtyTwoWeeks(thirtyTwoWeeksFormatted);

        }
        retrieveSixteenAndThirtyTwoWeeks();
        
    }, [monthlyWeightGain])

    //Check if the user can diet after bulking for 16 or 32 weeks
    useEffect(() => {
        if (dietAfterBulkWeeks === 'neither') {
            setDietAfterBulkNeither(true);
            setDietAfterBulkPossible(false);
            console.log('testing')
            
        } 
        if (dietAfterBulkWeeks === sixteenWeeks || dietAfterBulkWeeks === thirtyTwoWeeks) {
            setDietAfterBulkNeither(false);
            setDietAfterBulkPossible(true);

        }
    }, [dietAfterBulkWeeks, sixteenWeeks, thirtyTwoWeeks])

        
    //Submit the user's activity level and weight loss option
    const submitUserActivityLevelandWeightLossOption = async () => { 
        try {

            const date = new Date()
            if (weeklyWeightLoss && returnActivityLevel) {
                await axiosInstance.post('/api/query/calculate_maintenance_calories', {
                    weeklyWeightLoss,
                    returnActivityLevel,
                    date
                }, {
                    withCredentials: true
                })
            }
          
            navigate('/step-recommender')
            
            
        } catch (error) {
            console.error(error);
        }
        
    }

    //Submit the user's activity level and weight gain option
    const submitUserActivityLevelAndWeightGainOption = async () => {
        const date = new Date();
        try {
            if (monthlyWeightGain && returnActivityLevel) {
                await axiosInstance.post('/api/query/weightGain', {
                    monthlyWeightGain,
                    returnActivityLevel,
                    date,
                    dietAfterBulkWeeks
                }, {
                    withCredentials: true
                })
            }
            navigate('/weight-gain-recommender')
        } catch (error) {
            console.error(error);
        }
    }
 
    const submitUserActivityLevelForMaintenanceOption = async () => {
        try {
            const date = new Date();
            let response;
            if (returnActivityLevel) {
                response = await axiosInstance.post('/api/query/maintenance_phase',  {
                    returnActivityLevel,
                    date
                }, {
                    withCredentials: true
                })

            }

            if (response.status === 200) {
                navigate('/maintenance-phase')
            }
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div>
            <div>
                <p>What are you body weight related goals?</p>    
                <Dropdown
                    value={bodyWeightGoals}
                    options={[
                        { label: 'Weight Loss', value: 'weight_loss' },
                        { label: 'Weight Gain', value: 'weight_gain' },
                        { label: 'Maintain Weight', value: 'maintain_weight' },
                    ]}
                    onChange={(e) => setBodyWeightGoals(e.value)}
                />
            </div>
            
            {bodyWeightGoals === 'maintain_weight' &&
                <div>
                    <div>
                    <label>Choose your activity level:</label>
                    <div>
                        <Dropdown
                            value={returnActivityLevel}
                            options={[
                                { label: 'Sedentary', value: '1.2' },
                                { label: 'Lightly Active', value: '1.375' },
                                { label: 'Moderately Active', value: '1.55' },
                                { label: 'Very Active', value: '1.725' },
                                { label: 'Extra Active', value: '1.9' },
                            ]}
                            onChange={(e) => setReturnActivityLevel(e.value)}
                        />
                </div>
                    </div>
                    <p>
                        Maintainence phases are recommended when your overall fatigue is high
                        from dieting, training hard in the gym, or your life circumstances are
                        making it hard to fully commit to a fat loss or muscle gaining phase.
                    </p>
                    <p>
                        When you are ready to change to fat loss, or muscle gaining phase, utilized
                        the "Change Goals" button on the Weight Maintenance Assistant page.
                    </p>
                {returnActivityLevel &&
                    <div>
                        <Button
                            label="Continue"
                            onClick={submitUserActivityLevelForMaintenanceOption}
                        />
                    </div>
                }
                </div>
            }
            {showWeightLossOptions && bodyWeightGoals === 'weight_loss' &&
            <div>
                <h1>Weight Loss</h1>
                <div>
                    <Button
                        label="Weight Loss Tips"
                        onClick={(e) => setWeightLossTips(!weightLossTips)}
                        style={{

                        }}
                    />
                </div>
            {weightLossTips &&
                <div>
                    <p>
                        If you are primarily concerned with losing fat and preserving muscle,
                        the lower weight loss options will provide the best results.
                    </p>
                    <p>
                        The highest weight loss option is very difficult to achieve. You will likely
                        need to increase your daily physical activity dramactically. Additionally,
                        if you already on the leaner side, you are more likely to lose muscle.
                    </p>
                </div>
            }
                <label>Choose how much weight you'd like to lose per week:</label>
                <div>
                    <Dropdown
                        value={weeklyWeightLoss}
                        options={weightLossOrGainOptions}
                        onChange={(e) => setWeeklyWeightLoss(e.value)}
                        placeholder="lbs per week"
                    />
                </div>
                <label>Choose your activity level:</label>
                <div>
                    <Dropdown
                        value={returnActivityLevel}
                        options={[
                            { label: 'Sedentary', value: '1.2' },
                            { label: 'Lightly Active', value: '1.375' },
                            { label: 'Moderately Active', value: '1.55' },
                            { label: 'Very Active', value: '1.725' },
                            { label: 'Extra Active', value: '1.9' },
                        ]}
                        onChange={(e) => setReturnActivityLevel(e.value)}
                    />
                </div>
                {weeklyWeightLoss && returnActivityLevel &&
                <div>
                    <p>The maximum recommended length of a fat loss period is 12 weeks. That means, the most amount of
                        weight you can expect to lose is {Math.floor(weeklyWeightLoss * 12)} lbs. If your
                        goal weight requires you to lose more than {Math.floor(weeklyWeightLoss*12)} lbs
                        at the end of 12 weeks, you should take a month or so break from dieting and enter
                        a maintenance phase where you reduce your dieting fatigue and maintain your weight.
                        The Weight Loss Assistant will prompt you to switch to a maintenace or
                        bulk phase at the 12 week mark.
                    </p>
                    <p>
                        If you need to lose more weight and you feel confident you'll be successful in another 8-12 week cutting phase,
                        you can enter another fat loss phase. 
                    </p>
                    <Button
                        label="Continue"
                        onClick={submitUserActivityLevelandWeightLossOption}
                        style={{
                            backgroundColor: 'Yellow',
                            color: 'black',
                            padding: "6px 12px",
                            border: "solid 0.5px black",
                            borderRadius: "4px",
                            }}
                    />
                </div>
            }
                
            </div>
            }
            <div>
                {showWeightGainOptions &&
                    <div>
                        <h1> Weight Gain</h1>
                        <div>
                            <Button
                                label="Weight Gain Tips"
                                onClick={() => setWeightGainTips(!weightGainTips)}
                            />
                        </div>
                        {weightGainTips &&
                            <div>
                                <p>Option 1: If you are late in your weightlifting career or sensitive to how you look in the mirror with fat gain, this is the option we would recommend.</p>
                                <p>Option 2: If you are okay with putting on a little bit of extra fat for the chance at gaining more muscle over the bulking period, we would recommend this option</p>
                                <p>Option 3: If you are a beginner or primarily concerned with putting on as much muscle as possible, and don't mind looking flabby, or having to lose extra fat later, choose this option</p>
                            </div>
                        }
                        <label>Choose how much weight you'd like to gain per month</label>
                        <Dropdown
                            value={monthlyWeightGain}
                            options={weightLossOrGainOptions}
                            onChange={(e) => setMonthlyWeightGain(e.value)}
                            placeholder="lbs per month"


                        />
                        <div>
                            <label>What is your activity level?</label>
                            <div>
                                <Dropdown
                                    value={returnActivityLevel}
                                    options={[
                                        { label: 'Sedentary', value: '1.2' },
                                        { label: 'Lightly Active', value: '1.375' },
                                        { label: 'Moderately Active', value: '1.55' },
                                        { label: 'Very Active', value: '1.725' },
                                        { label: 'Extra Active', value: '1.9' },
                                    ]}
                                    onChange={(e) => setReturnActivityLevel(e.value)}
                                />
                            </div>
                        </div>
                        
                        <div>
                            {monthlyWeightGain && 
                                <div>
                                    <p>Around {sixteenWeeks} will you be able to diet for a month? If not, around {thirtyTwoWeeks}, will you be able to diet for two months?</p>
                                    <Dropdown
                                        value={dietAfterBulkWeeks}
                                        options={[
                                            { label: `Yes, ${sixteenWeeks} will work`, value: sixteenWeeks },
                                            { label: `Yes, ${thirtyTwoWeeks} will work`, value: thirtyTwoWeeks },
                                            { label: 'No, neither will work', value: 'neither' }
                                        ]}
                                        placeholder=""
                                        onChange={(e) => setDietAfterBulkWeeks(e.value)}
                                    
                                    />
                                
                                </div>
                                
                            }
                        </div>
                        <div>
                            {dietAfterBulkNeither &&
                                <div>
                                    <p>In this case, try to bulk for atleast 8 weeks. Once you are ready to lose the extra fat, change your goals on the weight gain page.</p>
                                </div>
                            }
                        </div>
                        <div>
                            {dietAfterBulkPossible &&
                                <div>
                                    <p>The weight management helper will keep track of how long you have been bulking for, and recommend that you switch to
                                        a weight loss phase around {dietAfterBulkWeeks}.
                                    </p>
                                </div>
                                
                            }
                        </div>
                        <div>
                            {(dietAfterBulkNeither || dietAfterBulkPossible) && monthlyWeightGain && returnActivityLevel &&
                                <div>
                                    <Button
                                    label="Continue"
                                    onClick={submitUserActivityLevelAndWeightGainOption}
                                    />
                                </div>
                            }
                        </div>
                            
                    </div>

                }
            </div>              
        </div>
    )

}

export default WeightLoss;