import React from "react";
import { Button } from 'primereact/button';
import classes from './login.module.css';
import logo from './idealfitnesslogo.png';

/*import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
*/



/*const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});
*/


const Login = () => {

    //const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');
    //const navigate = useNavigate();
    //const [emailValid, setEmailValid] = useState(true);
    //const [formValid, setFormValid] = useState(false);

    /*const handleLogin = async () => {
        
        
        if (formValid) {
            try {

                const response = await axiosInstance.post('/api/auth/login', {
                    email,
                    password
                }, {
                    withCredentials: true
                });
                console.log(response.data);
                const { url } = response.data;

                navigate(url);
            

            }catch (error) {
                console.error(error.response.data);
            }
        }
    }
    */

    const googleLogin = async () => {

        try {
            window.location.href =  process.env.REACT_APP_BASE_URL + '/api/auth/google';
        } catch (error) {
            console.error(error);
        }
    }

    const facebookLogin = async () => {
        try {
            window.location.href = process.env.REACT_APP_BASE_URL + '/api/auth/facebook';
        } catch (error) {
            console.error(error)
        }
    }



    /*const validateEmail = (email) => {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setEmailValid(isValid);
        setFormValid(isValid);


    }
    */
    
    
    return (
        <div className={classes.login}>
            {/*<InputText
                keyfilter = 'email'
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value);
                    validateEmail(e.target.value);
                }}
                className= {emailValid ? '' : 'p-invalid'}
                style={{
                    marginBottom: '5px'
                }}
            />
            <div>
                <InputText
                    type='password'
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <div>
                <Button
                    label="Sign In"
                    onClick={handleLogin}
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        borderRadius: '4px',
                        border: 'solid 1.5px black',
                        marginTop: '5px',
                        marginBottom: '5px'
                    }}
                    disabled={!formValid}
                />
            </div>
            */}
            
            <img src={logo} alt="Ideal Fitness Logo"
                style={{
                    height: '20rem',
                    width: '18rem',
                }}
            />
            <Button 
                icon="pi pi-google"
                label="Sign in with Google" 
                onClick={googleLogin}
                style={{ 
                    backgroundColor: 'black', 
                    color: 'white',
                    borderRadius: '4px',
                    width: '15rem',
                    marginBottom: '0.2rem'
                }}

            />
            <Button
                icon="pi pi-facebook"
                label="Sign in with Facebook"
                onClick={facebookLogin}
                style={{
                    width: '15rem',
                }}
            />
        </div>
    )
}

export default Login;