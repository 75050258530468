import {useState, useEffect} from 'react';
import axios from 'axios';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import classes from './Weightgain.module.css';
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
})

const WeightGainRecommender = () => {

    const [maintenanceCalories, setMaintenanceCalories] = useState('');
    const [dailyCalories, setDailyCalories] = useState('');
    const [caloriesConsumed, setCaloriesConsumed] = useState('');
    const [bodyWeight, setBodyWeight] = useState('');
    const [showAddMissedCaloriesAndWeight, setShowAddMissedCaloriesAndWeight] = useState(false);
    const [missedCalories, setMissedCalories] = useState('');
    const [missedBodyWeight, setMissedBodyWeight] = useState(null);
    const [missedDate, setMissedDate] = useState('');
    const [missedDayAddedSuccessfully, setMissedDayAddedSuccessfully] = useState(false);
    const [plannedWeightGain, setPlannedWeightGain] = useState('');
    const [expectedWeightGain, setExpectedWeightGain] = useState('');
    const [actualWeightGain, setActualWeightGain] = useState('');
    const [endOfBulkDate, setEndOfBulkDate] = useState(false);
    const [changeWeightGainCalories, setChangeWeightGainCalories] = useState(false);
    const [startWeek, setStartWeek] = useState('');
    const navigate = useNavigate();
    

    useEffect(() => {
        const fetchMaintainanceCalories = async () => {
            try {

                const response = await axiosInstance.get('/api/query/weight_gain_data', {

                    withCredentials: true,
                })

                setMaintenanceCalories(response.data.data.maintenance_calories)



            } catch (error) {
                console.log(error)

            }

        }
        fetchMaintainanceCalories();

    }, [])

    useEffect(() => {
        const fetchPlannedExpectedActual = async () => {
            try {

                const date = new Date();
                const isoDate = date.toISOString();
                const formattedDate = DateTime.fromISO(isoDate)
                const weekNumber = formattedDate.weekNumber;
                const dayNumber = formattedDate.weekday;

                const response = await axiosInstance.get(`/api/query/planned_expected_actual/${date}`, {
                    withCredentials: true
                })
                setPlannedWeightGain(response.data.data.plannedWeightGain.toFixed(2))
                setExpectedWeightGain(response.data.data.expectedWeightGain.toFixed(2))
                setActualWeightGain(response.data.data.actualWeightGain.toFixed(2))
                setStartWeek(response.data.data.startWeek)
            
                if ((expectedWeightGain - actualWeightGain > 0.5 || actualWeightGain - expectedWeightGain > 0.5) && 
                    weekNumber - startWeek >= 1 && weekNumber - startWeek < 4 && dayNumber === 1) {
                    setChangeWeightGainCalories(true)
                }
            
            
            } catch (error) {
                console.log(error)
            }
        }
        fetchPlannedExpectedActual();
    },[actualWeightGain, expectedWeightGain, startWeek])

    useEffect(() => {
        const fetchDailyCalories = async () => {
            try {

                const date = new Date();
                

                const response = await axiosInstance.get(`/api/query/adjusted_daily_calorie_recommendation/${date}`, {
                    withCredentials: true
                })
            

                setDailyCalories(response.data.data)


            } catch (error) {
                console.log(error)
            
            }
        }
        fetchDailyCalories();
    }, [])

    useEffect(() => {
        const fetchEndOfBulkDate = async () => {

            try {
                const response = await axiosInstance.get('/api/query/check_if_end_of_bulk', {
                    withCredentials: true
                })

                const date = new Date()
                const endOfBulk = new Date(response.data.data.end_of_bulk)


                if ( date >= endOfBulk) {
                    setEndOfBulkDate(true)
                }

            } catch (error) {
                console.log(error)
            }
        }
        fetchEndOfBulkDate();
    }, [])

    const submitDailyCaloriesAndWeight = async () => {
        
        const date = new Date();
        try {

            await axiosInstance.post('/api/query/daily_calories_and_weight', {
                caloriesConsumed,
                bodyWeight,
                date


            }, {
                withCredentials: true,
            
            })


        } catch (error) {
            console.log(error)
        }

    }

    const addMissedCaloriesAndSteps = () => {
        setShowAddMissedCaloriesAndWeight(!showAddMissedCaloriesAndWeight);


    }

    const submitAddMissedCaloriesAndSteps = async () => {
        try {

            const response =  await axiosInstance.post('/api/query/missed_calories_and_weight', {
                missedCalories,
                missedBodyWeight,
                missedDate

            }, {
                withCredentials: true,
            })

            if (response.status === 200) {
                setMissedDayAddedSuccessfully(true)
                setTimeout(() => {
                    setMissedDayAddedSuccessfully(false)
                    addMissedCaloriesAndSteps()
                    setMissedCalories('')
                    setMissedBodyWeight('')
                    setMissedDate('')
                
                }, 3000)
            }


        } catch (error) {
            console.log(error)

        }

    }

    const switchToCuttingPhase = async() => {
        try {
            const date = new Date();
            const response = await axiosInstance.post('/api/query/switch_to_cutting_phase', {
                date
            }, {
                withCredentials: true
            })
            console.log(response)

            if (response.status === 200) {
                navigate('/step-recommender')
            }


        } catch (error) {
            console.log(error)
        
        }

    }

    const extendBulkEndDate = async () => {

        try {
            const response = await axiosInstance.post('/api/query/extend_bulk_end_date', {}, {
                withCredentials: true
            })

            if (response.status === 200) {
                setEndOfBulkDate(false)
            }


        } catch (error) {
            console.log(error)
        }
    }

    const changeBulkCalories = async () => {
        try {

            const weightChange = expectedWeightGain - actualWeightGain;
            const response = await axiosInstance.post('/api/query/change_bulk_calories', {
                weightChange,
                dailyCalories
            }, {
                withCredentials: true
            })

            if (response.status === 200) {
                setChangeWeightGainCalories(false)
                window.location.reload();
            }
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <div className={classes.weightGain}>
            <h1>Weight Gain Assistant</h1>
            <div className={classes.mainAndBulkCalories}>
                <div>
                    <p style={{fontWeight: "bold"}}> Maint. Calories: {Math.floor(maintenanceCalories)} </p>
                    <div>
                        
                    </div>
                </div>
                <div>
                    <p>|</p>
                </div>
                <div>
                    <p style={{fontWeight: 'bold'}}>Bulking Calories: {Math.floor(dailyCalories)} </p>
                </div>
            </div>
            <div className={classes.plannedExpectedActualParent}>
                <div style={{fontWeight: 'bold'}}>Weight Gain</div>
                <div className={classes.plannedExpectedActual}>
                    <label style={{fontWeight: 'bold'}}>Planned:</label>
                    <div>{plannedWeightGain} lbs</div>
                    <div>|</div>
                    <label style={{fontWeight: 'bold'}}>Expected:</label>
                    <div>{expectedWeightGain} lbs</div>
                    <div>|</div>
                    <label style={{fontWeight: 'bold'}}>Actl:</label>
                    <div>{actualWeightGain} lbs</div>
                </div>

            </div>
            <div className={classes.bulkCalorieAndWeightInput}>
                
                
                    <div className={classes.bulkCalorieAndWeightChildren}>
                        <div>
                            <label style={{fontWeight: 'bold'}}>Daily Calorie Intake</label>
                            <input
                                value={caloriesConsumed}
                                type="number"
                                step="1"
                                onChange={(e) => setCaloriesConsumed(e.target.value)}
                                placeholder="Enter Calories"
                            />
                        </div>
                        <div>
                            <label style={{fontWeight: 'bold'}}>Body Weight</label>
                            <input
                                value={bodyWeight}
                                onChange={(e) => setBodyWeight(e.target.value)}
                                type="number"
                                step="0.01"
                                placeholder="Enter Weight(lbs)"
                            />
                        </div>
                    </div>
            </div>
            <div>
                <Button
                    label="Submit"
                    onClick={() => submitDailyCaloriesAndWeight()}
                />
                
            </div>
            <div>
                <label>Missed a day?</label>
                <div>
                    <Button
                        label='Add Day'
                        onClick={() => addMissedCaloriesAndSteps()}

                        />
                </div>
                {showAddMissedCaloriesAndWeight && 
                    <div>
                        <div>
                            <label>Daily Calorie Intake:</label>
                            <InputNumber
                                value={missedCalories}
                                onValueChange={(e) => setMissedCalories(e.value)}
                                placeholder="Enter Calories"
                                className="p-inputtext-sm"
                            />
                            <InputNumber
                                value={missedBodyWeight}
                                onChange={(e) => setMissedBodyWeight(e.value)}
                                mode="decimal"
                                minFractionDigits={1}
                                placeholder="Enter Weight(lbs)"
                                className="p-inputtext-sm"
                            />
                            <Calendar
                                value={missedDate}
                                onChange={(e) => setMissedDate(e.value)}
                                dateFormat="yy-mm-dd"
                                placeholder="Date"
                            />
                        </div>
                        <div>
                            <Button
                                label="Submit"
                                onClick={() => submitAddMissedCaloriesAndSteps()}
                            />

                        </div>

                    </div>
                    
                }
                {missedDayAddedSuccessfully && <p>Missed day added successfully</p>}
                {endOfBulkDate &&
                    <div>
                        <Dialog
                            header="End of Bulk"
                            visible={endOfBulkDate}
                            style={{width: '50vw'}}
                            onHide={() => setEndOfBulkDate(false)}

                        >
                            <p>Would you like to continue bulking, cut, or enter a
                                maintenance phase? For every month of bulking, we recommend
                                a month of cutting. If your fatigue is high, go into a 1-2 week maintenance
                                phase.
                            </p>
                            <Button
                                label="Continue Bulk"
                                onClick={() => extendBulkEndDate()}
                                
                            />
                            <Button
                                label="Cut"
                                onClick={() => switchToCuttingPhase()}
                            />


                        </Dialog>
                    </div>
                }
            </div>
            <Dialog
                header="Change Weight Gain Calories"
                visible={changeWeightGainCalories}
                style={{width: '50vw'}}
                onHide={() => setChangeWeightGainCalories(false)}
            >
                <p>Your actual weight gain is not within 0.5 lbs of your expected weight gain. 
                    If you have been entering your calories and weight consistently we'd recommend
                    adjustting your daily calorie intake. Would you like to adjust your calories?
                </p>
                <Button
                    label="Yes"
                    onClick={changeBulkCalories}
                />
                <Button
                    label="No"
                    onClick={() => setChangeWeightGainCalories(false)}
                />
                
                
            </Dialog>

        </div>


    )
}

export default WeightGainRecommender;